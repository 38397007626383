export class Usuarios {
    constructor(
        public idUsuario:number,
        public idTipo:number,
        public nombre:string,
        public apellidoP:string,
        public apellidoM:string,
        public correo:string,
        public usuario:string,
        public password:string,
    ){}
}