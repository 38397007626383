import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EscriturasService {
  url = "https://nuevoimf.angularimf.inmobiliariaimf.com/WebServices/index.php/Escrituras/";
  //url ="https://crm.inmobiliariaimf.com/WebServices/Escrituras/"
  constructor(public http:HttpClient) { }

  Escrituras(){
    return this.http.get(`${this.url}MostrarEscrituras`);
  }

  Clientes(){
    return this.http.get(`${this.url}MostrarClientes`);
  }

  Parcela(idCliente){
    return this.http.get(`${this.url}MostrarParcelas/${idCliente}`);
  }

  Lote(idCliente,idParcela){
    return this.http.get(`${this.url}MostrarLotes/${idCliente}/${idParcela}`);
  }

  GuardarEscritura(escritura){
    return this.http.post(`${this.url}InsertarEscritura`,JSON.stringify(escritura));
  }

  EditarEscritura(escritura){
    return this.http.post(`${this.url}ActualizarEscritura`,JSON.stringify(escritura));
  }

  AprobarEscritura(escritura){
    return this.http.post(`${this.url}AprobarEscritura`,JSON.stringify(escritura));
  }

  CancelarEscritura(escritura){
    return this.http.post(`${this.url}CancelarEscritura`,JSON.stringify(escritura));
  }

  Documentos(idCliente,idParcela,idLote){
    return this.http.get(`${this.url}MostrarDocumentos/${idCliente}/${idParcela}/${idLote}`);
  }

  ActualizarDocumento(documento){
    return this.http.post(`${this.url}ActualizarDocumento`,JSON.stringify(documento));
  }

  ReportePago(documento){
    return this.http.post(`${this.url}MostrarPagos`,JSON.stringify(documento));
  }

  Canceladas(){
    return this.http.get(`${this.url}CancelarEscritura`);
  }

}
