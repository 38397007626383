import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ClienteService } from '../../../Servicios/cliente.service';
import { UtilService } from '../../../util.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clientes } from '../../../Modelos/Clientes';
import { ClienteTel } from '../../../Modelos/ClienteTel';
import { ClienteDirCobro } from '../../../Modelos/ClienteDirCobro';

@Component({
  selector: 'app-cliente-show',
  templateUrl: './cliente-show.component.html',
  styleUrls: ['./cliente-show.component.scss']
})
export class ClienteShowComponent implements OnInit {
  Columns = ['nombre','direccion','correo','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  cliente = new Clientes(0,0,'','','','','',0,0,0,0,0,0,'','','',0,'',0);
  clientetel = new ClienteTel(0,0,0);
  clienteDir = new ClienteDirCobro(0,0,0,0,'',0,'',0,0);
  constructor(public http:ClienteService,public util:UtilService,public dialog:MatDialog) { }

  ngOnInit() {
    this.renderTable();
  }

  renderTable(){
    this.http.Clientes().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  OpenModal():void {
    const dialogRef = this.dialog.open(clientePop,{
      width:"auto",
      height:"auto",
      data: this.cliente
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  OpenTelefonos(row):void {
    this.clientetel = {idCliente:row.id,numero:0,codigo:0};
    const dialogRef = this.dialog.open(clienteTelPop,{
      width:"auto",
      height:"auto",
      data: this.clientetel 
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  CargarDatos(row){
    this.cliente  = {idCliente:row.id,idTipoCli:row.tipo,nombres:row.nombre,apellidoP:row.paterno,apellidoM:row.materno,ocupacion:row.ocupacion,correo:row.email,idPersonaCli:row.persona,idEstadoCivil:row.civil,idRegimen:row.regimen,idPais:row.pais,idEstado:row.estado,idCiudad:row.ciudad,CURP:row.curp,RFC:row.rfc,calle:row.calle,numero:row.numero,colonia:row.colonia,codigoPostal:row.cp};
    this.OpenModal();
  }

  Limpiar(){
    this.cliente  = {idCliente:0,idTipoCli:0,nombres:"",apellidoP:"",apellidoM:"",ocupacion:"",correo:"",idPersonaCli:0,idEstadoCivil:0,idRegimen:0,idPais:0,idEstado:0,idCiudad:0,CURP:"",RFC:"",calle:"",numero:0,colonia:"",codigoPostal:0};
  }

  Cuentas(row){
    this.util.getDatos(row);
    this.util.navegador("Clientes/Cuentas");
  }

  EliminarCliente(row){
    this.http.EliminarCliente(row.id).subscribe(result=>{
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

  AgregarContactos(row){
    this.util.getDatos(row.id);
    this.util.navegador("Clientes/Contacto");
  }

  DireccionesOpcional(idCliente){
    this.http.MostrarDirecciones(idCliente).subscribe(result=>{
      if(Object.values(result).length != 0){
        Object.values(result).forEach(element => {
          this.clienteDir = {idCliente:idCliente,idPais:element.pais,idEstado:element.estado,idCiudad:element.ciudad,calle:element.calle,numero:element.numero,colonia:element.colonia,codigoPostal:element.cp,long:1};
            this.OpendDialogDir();
        });
      }else {
        this.clienteDir = {idCliente:idCliente,idPais:0,idEstado:0,idCiudad:0,calle:"",numero:0,colonia:"",codigoPostal:0,long:0};
        this.OpendDialogDir();
      }
    },error=>{console.log(<any>error);});
  }

  OpendDialogDir(){
    const dialogRef = this.dialog.open(clienteDirPop,{
      height:"auto",
      width:"auto",
      data:this.clienteDir
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

}


@Component({
  selector:"clientePop",
  templateUrl:"clientePop.html"
})

export class clientePop implements OnInit {
  constructor(public modal:MatDialogRef<clientePop> ,public http:ClienteService,public util:UtilService,@Inject(MAT_DIALOG_DATA) public cliente:Clientes){}
  tPersona;  pais; estado; ciudad; estadoCivil; regimen; LongRFC;
  ngOnInit(){
    this.TipoPersona();
    this.EstadoCivil();
    this.Regimen();
    this.Pais();
    this.CargarDatos();
  }

  CargarDatos(){
    if(this.cliente.idPais != null || this.cliente.idPais != 0){
        this.Estados(this.cliente.idPais);
        this.Ciudad(this.cliente.idPais,this.cliente.idEstado);
    }
  }

  TipoPersona(){
    this.http.PersonaTipo().subscribe(result=>{
      this.tPersona = result;
    },error=>{console.log(<any>error);});
  }

  EstadoCivil(){
    this.http.EstadoCivil().subscribe(result=>{
      this.estadoCivil = result;
    },error=>{console.log(<any>error);});
  }

  Regimen(){
    this.http.Regimen().subscribe(result=>{
      this.regimen = result;
    },error=>{console.log(<any>error);});
  }

  Pais(){
    this.http.Pais().subscribe(result=>{
      this.pais = result;
    },error=>{console.log(<any>error);});
  }

  Estados(idPais){
    this.http.Estados(idPais).subscribe(result=>{
      this.estado = result;
    },error=>{console.log(<any>error);});
  }

  Ciudad(idPais,idCiudad){
    this.http.Ciudad(idPais,idCiudad).subscribe(result=>{
      this.ciudad = result;
    },error=>{console.log(<any>error);});
  }

  GuardarCliente(){
    this.http.GuardarClientes(this.cliente).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  EditarCliente(){
    this.http.EditarClientes(this.cliente).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Cerrar(){
    this.modal.close();
  }

}


@Component({
  selector:"clienteTelPop",
  templateUrl:"clienteTelPop.html",
  styleUrls: ['./cliente-show.component.scss']
})

export class clienteTelPop implements OnInit {
  constructor(public modal:MatDialogRef<clienteTelPop>,public http:ClienteService,@Inject(MAT_DIALOG_DATA) public telefono:ClienteTel){}
  Columns = ['codigo','telefono','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  validor = 0;

  ngOnInit(){
    this.renderTabla();
  }

  renderTabla(){
    this.http.ClienteTelefonos(this.telefono.idCliente).subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  Mostrar(){
    this.validor = 1;
  }

  Cancelar(){
    this.validor = 0;
  }

  Guardar(){
    this.http.GuardarTelefono(this.telefono).subscribe(result=>{
      console.log(result);
      this.renderTabla();
      this.Cancelar();
    },error=>{console.log(<any>error);});
  }

  EliminarTel(row){
    this.telefono = {idCliente:row.cliente,codigo:row.codigo,numero:row.telefono};
    this.http.EliminarTelefono(this.telefono).subscribe(result=>{
      this.renderTabla();
    },error=>{console.log(<any>error);});
  }
}

@Component({
  selector:"clienteDirPop",
  templateUrl:"clienteDirPop.html"
})

export class clienteDirPop implements OnInit {
  constructor(public modal:MatDialogRef<clienteDirPop>,public http:ClienteService,@Inject(MAT_DIALOG_DATA) public direccion:ClienteDirCobro){}
  pais; estado; ciudad;
  ngOnInit(){
    this.Pais();
    this.Ubicador();
  }

  Ubicador(){
    if(this.direccion.long == 1){
      this.Estado();
      this.Ciudad();
    }
  }

  Pais(){
    this.http.Pais().subscribe(result=>{
      this.pais = result;
    },error=>{console.log(<any>error);});
  }

  Estado(){
    this.http.Estados(this.direccion.idPais).subscribe(result=>{
      this.estado = result;
    },error=>{console.log(<any>error);});
  }

  Ciudad(){
    this.http.Ciudad(this.direccion.idPais,this.direccion.idEstado).subscribe(result=>{
      this.ciudad = result;
    },error=>{console.log(<any>error);});
  }

  Guardar(){
    this.http.InsertarDireccion(this.direccion).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Editar(){
    this.http.ActualizarDireccion(this.direccion).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }


  Cerrar(){
    this.modal.close();
  }

}