export class Compras {
    constructor(
        public idParcela:number,
        public nombreParcela:string,
        public idProveedor:number,
        public nombreProveedor:string,
        public costoM2:number,
        public area:number,
        public ubicador:number
    ){}
}