import { OnInit, ViewChild, Inject, Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VentasService } from '../../../Servicios/ventas.service';
import { UtilService } from '../../../util.service';
import { Documentos } from '../../../Modelos/Documentos';
@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {
  Columns = ['nombreDocumento','nombreParcela','numerolote','nombreCliente','estatusDocumento','capital','interes','descuento','vencimiento','observacion','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  documento = new Documentos(0,'',0,'',0,'',0,'','',0,'',0,0,0);
  constructor(public http:VentasService,public util:UtilService, public dialog:MatDialog) { }
  ngOnInit() {
    var datos = this.util.showDatos();
    this.documento.idCliente = datos.cliente;
    this.documento.idParcela = datos.parcela;
    this.documento.idLote = datos.lote;
    this.renderTable();
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  renderTable(){
    this.http.Documentos(this.documento.idCliente,this.documento.idParcela,this.documento.idLote).subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  OpendModal():void{
    const dialogRef = this.dialog.open(documentoPop,{
      width:"auto",
      height:"auto",
      data:this.documento
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  CargarDatos(row){
    this.documento = {idDocumento:row.id,documento:row.nombreDocumento,idCliente:row.cliente,cliente:row.nombreCliente,idParcela:row.parcela,parcela:row.nombreParcela,idLote:row.lote,lote:row.numerolote,reporte:row.vencimiento,promesa:row.promesa,importe:row.importe,descuento:row.descuento,capital:row.capital,interes:row.interes}
    this.OpendModal();
  }

  ReportePagos(row){
    this.util.getDatos(row);
    this.util.navegador("Ventas/Reporte/Pagos");
  }

}


@Component({
  selector:"documentoPop",
  templateUrl:"documentoPop.html",
  providers:[{provide:DateAdapter,useClass:MomentDateAdapter,deps:[MAT_DATE_LOCALE]},
  {provide:MAT_DATE_FORMATS,useValue:MAT_MOMENT_DATE_FORMATS}],
})

export class documentoPop implements OnInit {
  constructor(public modal:MatDialogRef<documentoPop>,public http:VentasService,@Inject(MAT_DIALOG_DATA) public documento:Documentos,public adapter:DateAdapter<any>){}
  descuentoNum=0; intereses=0;
  ngOnInit(){
    this.adapter.setLocale('es');
  }

  CalcularDes(){
    this.descuentoNum = parseFloat((this.documento.importe * (this.documento.descuento / 100)).toFixed(2));
  }

  CalcularInt(){
    this.intereses =parseFloat((this.documento.importe * (this.documento.interes / 100)).toFixed(2)) ;
  }

  ActualizarDocumento(){
    this.http.EditarDocumento(this.documento).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.modal.close();
  }

}