import { Component, OnInit } from '@angular/core';
import { ProveedoresService } from '../../../Servicios/proveedores.service';
import { Proveedor } from '../../../Modelos/Proveedor';
import { UtilService } from '../../../util.service';

@Component({
  selector: 'app-proveedores-editar',
  templateUrl: './proveedores-editar.component.html',
  styleUrls: ['./proveedores-editar.component.scss']
})
export class ProveedoresEditarComponent implements OnInit {
  proveedor = new Proveedor(0,'','',0,0,'',0,'',0,0,0,0);
  tPersona; tProveedor; paises; estados; ciudades; 
  constructor(public http:ProveedoresService,public util:UtilService) { }

  ngOnInit() {
    this.CargarDatos();
    this.TipoPersona();
    this.TipoProveedor();
    this.Pais();
  }

  TipoPersona(){
    this.http.PersonaTipo().subscribe(result=>{
      this.tPersona = result;
    },error=>{console.log(<any>error);});
  }

  TipoProveedor(){
    this.http.ProveedoresTipo().subscribe(result=>{
      this.tProveedor = result;
    },error=>{console.log(<any>error);});
  }

  Pais(){
    this.http.Paises().subscribe(result=>{
      this.paises = result;
    },error=>{console.log(<any>error);});
  }

  CargarDatos(){
    var datos = this.util.showDatos();
    this.proveedor = {idProveedor:datos.id,nombre:datos.nombre,RFC:datos.rfc,tipoPro:datos.tipo,persona:datos.persona,calle:datos.calle,numero:datos.numero,colonia:datos.colonia,codigoPostal:datos.cp,idCiudad:datos.ciudad,idEstado:datos.estado,idPais:datos.pais};
    this.obtPais(this.proveedor.idPais);
    this.obtEstado(this.proveedor.idPais,this.proveedor.idEstado);
  }

  obtPais(idPais){
    this.http.Estados(idPais).subscribe(result=>{
      this.estados = result;
    },error=>{console.log(<any>error);});
  }

  obtEstado(idPais,idEstado){
    this.http.Ciudad(idPais,idEstado).subscribe(result=>{
      this.ciudades = result;
    },error=>{console.log(<any>error);});
  } 

  Editar(){
    this.http.EditarProveedor(this.proveedor).subscribe(result=>{
      console.log(result);
      this.util.navegador("Proveedores");
    },error=>{console.log(<any>error);});
  }

}
