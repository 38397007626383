import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfiguracionService } from '../../../Servicios/configuracion.service';
import { Empresa } from '../../../Modelos/Empresa';
import { UtilService } from '../../../util.service';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {
  Columns = ['nombre','rfc','direccion','Ubicacion','Acciones'];
  dataSource:any; buttonHiden=0;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  empresa = new Empresa(0,0,0,0,'','','','','',0);
  constructor(public http:ConfiguracionService,public dialog:MatDialog,public util:UtilService) { }

  ngOnInit() {
    this.renderTable();
  }

  renderTable(){
    this.http.Empresas().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
      this.ValidadorButtonCrear();
    },error=>{console.log(<any>error);});
  }

  ValidadorButtonCrear(){
    if(this.dataSource.data.length != 0){
      this.buttonHiden = 1;
    }
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  OpenModal():void {
    const dialogRef = this.dialog.open(empresaPop,{
      width:"auto",
      height:"auto",
      data: this.empresa
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  CargarDatos(row){
    this.empresa = {idEmpresa:row.id,idPais:row.pais,idEstado:row.estado,idCiudad:row.ciudad,rfc:row.rfc,nombre:row.nombre,calle:row.calle,numero:row.numero,colonia:row.colonia,codigoPostal:row.cp};
    this.OpenModal();
  }

  Limpiar(){
    this.empresa = {idEmpresa:this.empresa.idEmpresa,idPais:0,idEstado:0,idCiudad:0,rfc:"",nombre:"",calle:"",numero:"",colonia:"",codigoPostal:0};
  }

  Eliminar(row){
    this.empresa = {idEmpresa:row.id,idPais:row.pais,idEstado:row.estado,idCiudad:row.ciudad,rfc:row.rfc,nombre:row.nombre,calle:row.calle,numero:row.numero,colonia:row.colonia,codigoPostal:row.cp};
    this.http.ElminarEmpresa(this.empresa).subscribe(result=>{
      console.log(result);
    },error=>{console.log(<any>error);});
  }

  CuentasBancarias(row){
    this.util.getDatos(row);
    this.util.navegador("Configuracion/Empresa/Cuentas");
  }

}

@Component({
  selector:"empresaPop",
  templateUrl:"empresaPop.html"
})

export class empresaPop implements OnInit {
  constructor(public modal:MatDialogRef<empresaPop>,public http:ConfiguracionService,@Inject(MAT_DIALOG_DATA) public empresa:Empresa){}
  paises; estados; ciudades;
  ngOnInit(){
    this.Paises();
    this.CargarUbicacion();
  }

  Paises(){
    this.http.Paises().subscribe(result=>{
      this.paises = result;
    },error=>{console.log(<any>error);});
  }

  Estados(idP){
    this.http.EstadoPais(idP).subscribe(result=>{
      this.estados = result;
    },error=>{console.log(<any>error);});
  }

  Ciudad(idPais,idEstado){
    this.http.CiudadEstado(idPais,idEstado).subscribe(result=>{
      this.ciudades = result;
    },error=>{console.log(<any>error);});
  }

  CargarUbicacion(){
    if(this.empresa.idEmpresa != 0){
      this.Estados(this.empresa.idPais);
      this.Ciudad(this.empresa.idPais,this.empresa.idEstado);
    }
  }

  GuardarEmpresa(){
    this.http.CrearEmpresa(this.empresa).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  EditarEmpresa(){
    this.http.EditarEmpresa(this.empresa).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Cerrar(){
    this.modal.close();
  }

}