export class Empresa {
    constructor(
        public idEmpresa:number,
        public idPais:number,
        public idEstado:number,
        public idCiudad:number,
        public rfc:string,
        public nombre:string,
        public calle:string,
        public numero:string,
        public colonia:string,
        public codigoPostal:number
    ){}
}