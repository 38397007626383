export class Separacion {
    constructor(
        public idCliente:number,
        public idParcela:number,
        public idLote:number,
        public idAgente:number,
        public enganche:number,
        public nomParcela:string,
        public nombCliente:string,
        public ubicador:number
    ){}
}