import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EscriturasService } from '../../../Servicios/escrituras.service';
import { UtilService } from '../../../util.service';

@Component({
  selector: 'app-escrituras-canceladas',
  templateUrl: './escrituras-canceladas.component.html',
  styleUrls: ['./escrituras-canceladas.component.scss']
})
export class EscriturasCanceladasComponent implements OnInit {
  Columns = ['nombreParcela','numeroLote','nombreCliente','estatusEscritura','precio','plazo','fijo','inicio','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  constructor(public http:EscriturasService,public util:UtilService) { }

  ngOnInit() {
    this.RenderTabla();
  }

  RenderTabla(){
    this.http.Canceladas().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

}
