export class PagoEscritura {
    constructor(
        public idPago:number,
        public idDocumento:number,
        public idCliente:number,
        public nombreCliente:string,
        public idParcela:number, 
        public idLote:number,
        public idUsuario:number,
        public idForma:number,
        public idTipo:number,
        public observacion:string,
        public importe:number,
        public restante:number,
        public reporte:string
    ){}
}