import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfiguracionService } from '../../../Servicios/configuracion.service';
import { Ciudad } from '../../../Modelos/Ciudad';

@Component({
  selector: 'app-ciudad',
  templateUrl: './ciudad.component.html',
  styleUrls: ['./ciudad.component.scss']
})
export class CiudadComponent implements OnInit {
  Columns = ['nombrePais','nombreEstado','nombreCiudad','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  ciudad = new Ciudad(0,0,0,'');
  constructor(public http:ConfiguracionService,public dialog:MatDialog) { }

  ngOnInit() {
    this.renderTable();
  }

  renderTable(){
    this.http.Ciudades().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  Limpiar(){
    this.ciudad = {idPais:0,idEstado:0,idCiudad:0,nomCiudad:""};
  }

  OpenModal():void {
    const dialogRef = this.dialog.open(CiudadPop,{
      width:"400",
      height:"400",
      data:this.ciudad
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  Cargar(row){
    this.ciudad = {idPais:row.idPais,idEstado:row.idEstado,idCiudad:row.idCiudad,nomCiudad:row.nombreCiudad};
    this.OpenModal();
  }

  Eliminar(row){
    this.http.EliminarCiudad(row).subscribe(result=>{
      console.log(result);
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

}

@Component({
  selector:"CiudadPop",
  templateUrl:"CiudadPop.html"
})

export class CiudadPop implements OnInit{
  paises; estados;
  constructor(public modal:MatDialogRef<CiudadPop>,public http:ConfiguracionService,@Inject(MAT_DIALOG_DATA) public ciudad:Ciudad){}

  ngOnInit(){
    this.blockButton();
    this.Paises();
  }

  blockButton(){
    if(this.ciudad.idCiudad == 0){
      document.getElementById("Editar").style.display ="none";
    }else{
      document.getElementById("Guardar").style.display = "none";
      this.Estados(this.ciudad.idEstado);
    }
  }

  Paises(){
    this.http.Paises().subscribe(result=>{
      this.paises = result;
    },error=>{console.log(<any>error);});
  }

  Estados(idP){
    this.http.EstadoPais(idP).subscribe(result=>{
      this.estados = result;
    },error=>{console.log(<any>error);});
  }

  onNoClick():void{
    this.modal.close();
  }

  GuardarCiudad(){
    this.http.GuardarCiudad(this.ciudad).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  EditarCiudad(){
    this.http.EditarCiudad(this.ciudad).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);}); 
  }

}