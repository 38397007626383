import {ModuleWithProviders} from '@angular/core';
import {Routes,RouterModule} from '@angular/router';

import { PaisComponent } from '../app/Componentes/Configuraciones/pais/pais.component';
import { EstadoComponent } from './Componentes/Configuraciones/estado/estado.component';
import { CiudadComponent } from './Componentes/Configuraciones/ciudad/ciudad.component';
import { BancoComponent } from './Componentes/Configuraciones/banco/banco.component';
import { ProveedoresShowComponent } from './Componentes/Proveedor/proveedores-show/proveedores-show.component';
import { ProveedoresCrearComponent } from './Componentes/Proveedor/proveedores-crear/proveedores-crear.component';
import { ProveedoresEditarComponent } from './Componentes/Proveedor/proveedores-editar/proveedores-editar.component';
import { ProveedoresCuentasComponent } from './Componentes/Proveedor/proveedores-cuentas/proveedores-cuentas.component';
import { ProveedoresContactosComponent } from './Componentes/Proveedor/proveedores-contactos/proveedores-contactos.component';
import { ParcelaShowComponent } from './Componentes/Parcela/parcela-show/parcela-show.component';
import { ParcelaCrearComponent } from './Componentes/Parcela/parcela-crear/parcela-crear.component';
import { LotesComponent } from './Componentes/Parcela/lotes/lotes.component'
import { EmpresaComponent } from './Componentes/Configuraciones/empresa/empresa.component';
import { EmpresaCuentasComponent } from './Componentes/Configuraciones/empresa-cuentas/empresa-cuentas.component';
import { UsuariosComponent } from './Componentes/Configuraciones/usuarios/usuarios.component';
import { ClienteShowComponent } from './Componentes/Clientes/cliente-show/cliente-show.component';
import { ClienteContactosComponent } from './Componentes/Clientes/cliente-contactos/cliente-contactos.component';
import { ClienteCuentasComponent } from './Componentes/Clientes/cliente-cuentas/cliente-cuentas.component';
import { ComprasComponent } from './Componentes/Compra/compras/compras.component';
import { SeparacionComponent } from './Componentes/Separaciones/separacion/separacion.component';
import { VentasComponent } from './Componentes/Venta/ventas/ventas.component';
import { LoginComponent } from './Componentes/Login/login/login.component';
import { DocumentosComponent } from './Componentes/Venta/documentos/documentos.component';
import { ReportePagosDocumentosComponent } from './Componentes/Venta/reporte-pagos-documentos/reporte-pagos-documentos.component';
import { PagosComponent } from './Componentes/Caja/pagos/pagos.component'; 
import { CanceladasComponent } from './Componentes/Separaciones/canceladas/canceladas.component';
import { EscriturasComponent } from './Componentes/Escritura/escrituras/escrituras.component';
import { EscriturasDocumentosComponent } from './Componentes/Escritura/escrituras-documentos/escrituras-documentos.component';
import { EscriturasDocumentoPagoComponent } from './Componentes/Escritura/escrituras-documento-pago/escrituras-documento-pago.component';
import { VentasCanceladasComponent } from './Componentes/Venta/ventas-canceladas/ventas-canceladas.component';
import { EscriturasCanceladasComponent } from './Componentes/Escritura/escrituras-canceladas/escrituras-canceladas.component';

const appRoutes:Routes = [
    {path:'Proveedores',component:ProveedoresShowComponent},
    {path:'Proveedores/Crear',component:ProveedoresCrearComponent},
    {path:'Proveedores/Editar',component:ProveedoresEditarComponent},
    {path:'Proveedores/Cuenta',component:ProveedoresCuentasComponent},
    {path:'Proveedores/Contactos',component:ProveedoresContactosComponent},
    {path:'Clientes',component:ClienteShowComponent},
    {path:'Clientes/Contacto',component:ClienteContactosComponent},
    {path:'Clientes/Cuentas',component:ClienteCuentasComponent},
    {path:'Compras',component:ComprasComponent},
    {path:'Separaciones',component:SeparacionComponent},
    {path:'Separaciones/Canceladas',component:CanceladasComponent},
    {path:'Ventas',component:VentasComponent},
    {path:'Ventas/Documentos',component:DocumentosComponent},
    {path:'Ventas/Reporte/Pagos',component:ReportePagosDocumentosComponent},
    {path:'Ventas/Canceladas',component:VentasCanceladasComponent},
    {path:'Escritura',component:EscriturasComponent},
    {path:'Escritura/Documentos',component:EscriturasDocumentosComponent},
    {path:'Escritura/Reporte/Pago',component:EscriturasDocumentoPagoComponent},
    {path:'Escritura/Canceladas',component:EscriturasCanceladasComponent},
    {path:'Pagos',component:PagosComponent},
    {path:'Parcelas',component:ParcelaShowComponent},
    {path:'Parcelas/Crear',component:ParcelaCrearComponent},
    {path:'Parcelas/Lotes',component:LotesComponent},
    {path:'Configuracion/Pais',component:PaisComponent},
    {path:'Configuracion/Estado',component:EstadoComponent},
    {path:'Configuracion/Ciudad',component:CiudadComponent},
    {path:'Configuracion/Banco',component:BancoComponent},
    {path:'Configuracion/Usuarios',component:UsuariosComponent},
    {path:'Configuracion/Empresa',component:EmpresaComponent},
    {path:'Configuracion/Empresa/Cuentas',component:EmpresaCuentasComponent},
    {path:'Login',component:LoginComponent}
];

export const appRoutingProviders:any[]=[];
export const routing:ModuleWithProviders = RouterModule.forRoot(appRoutes);