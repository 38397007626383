import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  url = "https://nuevoimf.angularimf.inmobiliariaimf.com/WebServices/index.php/Clientes/"
  //url = "https://crm.inmobiliariaimf.com/WebServices/Clientes/";
  constructor(public http:HttpClient) { }

  TipoCliente(){
    return this.http.get(`${this.url}TipoCliente`);
  }

  PersonaTipo(){
    return this.http.get(`${this.url}TipoPersona`);
  }

  EstadoCivil(){
    return this.http.get(`${this.url}CivilPersona`);
  }

  Regimen(){
    return this.http.get(`${this.url}RegimenPersona`);
  }

  Pais(){
    return this.http.get(`${this.url}Pais`);
  }

  Estados(idPais){
    return this.http.get(`${this.url}EstadoPais/${idPais}`);
  }

  Ciudad(idPais,idEstado){
    return this.http.get(`${this.url}CiudadEstado/${idPais}/${idEstado}`);
  }

  Clientes(){
    return this.http.get(`${this.url}MostrarClientes`);
  }

  GuardarClientes(cliente){
    return this.http.post(`${this.url}InsertarCliente`,JSON.stringify(cliente));
  }

  EditarClientes(cliente){
    return this.http.post(`${this.url}ActualizarCliente`,JSON.stringify(cliente));
  }

  EliminarCliente(idCliente){
    return this.http.get(`${this.url}EliminarCliente/${idCliente}`);
  }

  ClienteTelefonos(idCliente){
    return this.http.get(`${this.url}MostrarTelefonos/${idCliente}`);
  }

  GuardarTelefono(telefono){
    return this.http.post(`${this.url}InsertarTelefono`,JSON.stringify(telefono));
  }

  EliminarTelefono(telefono){
    return this.http.post(`${this.url}EliminarTelefono`,JSON.stringify(telefono));
  }

  ClienteContactos(idCliente){
    return this.http.get(`${this.url}MostrarContactos/${idCliente}`);
  }

  ContactoTipo(){
    return this.http.get(`${this.url}TipoContacto`);
  }

  GuardarContacto(contacto){
    return this.http.post(`${this.url}InsertarContacto`,JSON.stringify(contacto));
  }

  ActualizarContacto(contacto){
    return this.http.post(`${this.url}ActualizarContacto`,JSON.stringify(contacto));
  }

  BorrarContacto(idCliente,idContacto){
    return this.http.get(`${this.url}EliminarContacto/${idCliente}/${idContacto}`);
  }

  TelefonosContacto(idCliente,idContacto){
    return this.http.get(`${this.url}MostrarTelefonos2/${idCliente}/${idContacto}`);
  }

  GuardarTelContacto(telefono){
    return this.http.post(`${this.url}InsertarTelefono2`,JSON.stringify(telefono));
  }

  EliminarTelContacto(telefono){
    return this.http.post(`${this.url}EliminarTelefono2`,JSON.stringify(telefono));
  }

  CuentaBancarias(idCliente){
    return this.http.get(`${this.url}MostrarCuentas/${idCliente}`);
  }

  Bancos(){
    return this.http.get(`${this.url}Banco`);
  }

  GuardarCuentas(cuentas){
    return this.http.post(`${this.url}InsertarCuenta`,JSON.stringify(cuentas));
  }

  EliminarCuenta(cuenta){
    return this.http.post(`${this.url}EliminarCuenta`,JSON.stringify(cuenta));
  }

  MostrarDirecciones(idCliente){
    return this.http.get(`${this.url}MostrarDireccion/${idCliente}`);
  }

  InsertarDireccion(direccion){
    return this.http.post(`${this.url}InsertarDireccion`,JSON.stringify(direccion));
  }

  ActualizarDireccion(direccion){
    return this.http.post(`${this.url}ActualizarDireccion`,JSON.stringify(direccion));
  }

}
