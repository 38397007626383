import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfiguracionService } from '../../../Servicios/configuracion.service';
import { Estado } from '../../../Modelos/Estado';

@Component({
  selector: 'app-estado',
  templateUrl: './estado.component.html',
  styleUrls: ['./estado.component.scss']
})
export class EstadoComponent implements OnInit {
  Columns = ['nombrePais','nombreEstado','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  estado = new Estado(0,0,'');
  constructor(public http:ConfiguracionService,public dialog:MatDialog) { }

  ngOnInit() {
    this.renderTable();
  }

  renderTable(){
    this.http.Estados().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  Limpiar(){
    this.estado = {idPais:0,idEstado:0,nomEstado:""};
  }

  OpenModal():void {
    const dialogRef = this.dialog.open(EstadoPop,{
      width:"400",
      height:"400",
      data:this.estado
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  Cargar(row){
    this.estado = {idPais:row.idPais,idEstado:row.idEstado,nomEstado:row.nombreEstado};
    this.OpenModal();
  }

  Eliminar(row){
    this.http.EliminarEstado(row).subscribe(result=>{
      console.log(result);
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

}

@Component({
  selector:"EstadoPop",
  templateUrl:"EstadoPop.html"
})

export class EstadoPop implements OnInit {
  paises;
  constructor(public modal:MatDialogRef<EstadoPop>,public http:ConfiguracionService,@Inject(MAT_DIALOG_DATA) public estado:Estado){}

  ngOnInit(){
    this.Paises();
    this.blockButton();
  }

  blockButton(){
    if(this.estado.idEstado == 0){
      document.getElementById("Editar").style.display ="none";
    }else{
      document.getElementById("Guardar").style.display = "none";
    }
  }

  Paises(){
    this.http.Paises().subscribe(result=>{
      this.paises = result;
    },error=>{console.log(<any>error);});
  }

  onNoClick():void{
    this.modal.close();
  }

  GuardarEstado(){
    this.http.EstadoCrear(this.estado).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  EditarEstado(){
    this.http.EditarEstado(this.estado).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

}