import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SeparacionService {
  url = "https://nuevoimf.angularimf.inmobiliariaimf.com/WebServices/index.php/Separaciones/";
  //url = "https://crm.inmobiliariaimf.com/WebServices/Separaciones/";
  constructor(public http:HttpClient) { }

  Separaciones(){
    return this.http.get(`${this.url}MostrarSeparaciones`);
  }

  Clientes(){
    return this.http.get(`${this.url}MostrarClientes`);
  }

  Parcelas(){
    return this.http.get(`${this.url}MostrarParcelas`);
  }

  Lotes(idParcela){
    return this.http.get(`${this.url}MostrarLotes/${idParcela}`);
  }

  Agentes(){
    return this.http.get(`${this.url}MostrarAgentes`);
  }

  GuardarSeparacion(separacion){
    return this.http.post(`${this.url}InsertarSeparacion`,JSON.stringify(separacion));
  }

  ActualizarSeparacion(separacion){
    return this.http.post(`${this.url}ActualizarSeparacion`,JSON.stringify(separacion));
  }

  Cancelacion(separacion){
    return this.http.post(`${this.url}CancelarSeparacion`,JSON.stringify(separacion));
  }

  EliminarSeparacion(separacion){
    return this.http.post(`${this.url}EliminarSeparacion`,JSON.stringify(separacion));
  }

  SeparacionesCanceladas(){
    return this.http.get(`${this.url}MostrarCanceladas`);
  }

}
