export class Parcela {
    constructor(
        public idParcela:number, 
        public nombre:string,
        public idTipo:number,
        public idPais:number,
        public idEstado:number,
        public idCiudad:number,
        public vendible:number,
        public municipal:number,
        public vial:number,
        public norte:string,
        public noreste:string,
        public este:string,
        public sureste:string,
        public sur:string,
        public suroeste:string,
        public oeste:string,
        public noroeste:string
    ){}
}