import { Component, OnInit } from '@angular/core';
import { ProveedoresService } from '../../../Servicios/proveedores.service';
import { Proveedor } from '../../../Modelos/Proveedor';
import { UtilService } from '../../../util.service';

@Component({
  selector: 'app-proveedores-crear',
  templateUrl: './proveedores-crear.component.html',
  styleUrls: ['./proveedores-crear.component.scss']
})
export class ProveedoresCrearComponent implements OnInit {
  tPersona; tProveedor; paises; estados; ciudades; 
  constructor(public http:ProveedoresService,public util:UtilService) { }
  proveedor = new Proveedor(0,'','',0,0,'',0,'',0,0,0,0)
  ngOnInit() {
    this.TipoPersona();
    this.TipoProveedor();
    this.Paise();
  }

  TipoPersona(){
    this.http.PersonaTipo().subscribe(result=>{
      this.tPersona = result;
    },error=>{console.log(<any>error);});
  }

  TipoProveedor(){
    this.http.ProveedoresTipo().subscribe(result=>{
      this.tProveedor = result;
    },error=>{console.log(<any>error);});
  }

  Paise(){
    this.http.Paises().subscribe(result=>{
      this.paises = result;
    },error=>{console.log(<any>error);});
  }

  obtPais(idPais){
    this.http.Estados(idPais).subscribe(result=>{
      this.estados = result;
    },error=>{console.log(<any>error);});
  }

  obtEstado(idPais,idEstado){
    this.http.Ciudad(idPais,idEstado).subscribe(result=>{
      this.ciudades = result;
    },error=>{console.log(<any>error);});
  } 

  Guardar(){
    this.http.GuardarProveedor(this.proveedor).subscribe(result=>{
      console.log(result);
      this.util.navegador("Proveedores");
    },error=>{console.log(<any>error);});
  }

}
