import { Component, ChangeDetectorRef,OnDestroy, OnInit } from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import { ConfiguracionService } from './Servicios/configuracion.service';
import { UtilService} from './util.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'IMF';
  mobileQuery:MediaQueryList;
  private mobileQueryListener:() => void;
  constructor(public http:ConfiguracionService,public util:UtilService,changeDetectorRef:ChangeDetectorRef, media:MediaMatcher){
    this.mobileQuery = media.matchMedia('(max-width: 900px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
  }
  rol; id;
  ngOnInit(){
    this.rol = localStorage.Rol;
    this.id = localStorage.idUsuario;
    if(this.id == undefined || this.rol == undefined){
      this.util.navegador("Login");
    }
  }

  ngOnDestroy(){
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  navegador(param){
    this.util.navegador(param);
  }

}
