import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { EscriturasService } from '../../../Servicios/escrituras.service';
import { UtilService } from '../../../util.service';
import { EscrituraDocumentos } from '../../../Modelos/EscrituraDocumento';


@Component({
  selector: 'app-escrituras-documentos',
  templateUrl: './escrituras-documentos.component.html',
  styleUrls: ['./escrituras-documentos.component.scss']
})
export class EscriturasDocumentosComponent implements OnInit {
  documentos = new EscrituraDocumentos(0,'',0,'',0,'',0,'',0,0,0,'','');
  constructor(public http:EscriturasService,public util:UtilService,public dialog:MatDialog) { }
  Columns = ['nombreDocumento','nombreParcela','numeroLote','nombreCliente','estatusDocumento','importe','capitalDocumento','interesDocumento','pagado','restante','vencimiento','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;

  ngOnInit() {
    var datos = this.util.showDatos();
    this.documentos.idParcela = datos.parcela;
    this.documentos.idLote = datos.lote;
    this.documentos.idCliente = datos.cliente;
    this.RenderTable();
  }

  RenderTable(){
    this.http.Documentos(this.documentos.idCliente,this.documentos.idParcela,this.documentos.idLote).subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  Cargar(row){
    this.documentos = {idDocumento:row.id,nombreDocumento:row.nombreDocumento,idCliente:row.cliente,nombreCliente:row.nombreCliente,idParcela:row.parcela,nombreParcela:row.nombreParcela,idLote:row.lote,nombreLote:row.numeroLote,importe:row.importe,capital:row.capital,interes:row.interes,reporte:row.vencimiento,promesa:row.promesa}
    this.Documento();
  }

  Documento(){
    const dialogRef = this.dialog.open(EscrituraDocPop,{
      width:"auto",
      height:"auto",
      data:this.documentos
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.RenderTable();
    });
  }

  ReportePagos(row){
    this.util.getDatos(row);
    this.util.navegador("Escritura/Reporte/Pago");
  }

}


@Component({
  selector:"EscrituraDocPop",
  templateUrl:"EscrituraDocPop.html",
  providers:[{provide:DateAdapter,useClass:MomentDateAdapter,deps:[MAT_DATE_LOCALE]},
  {provide:MAT_DATE_FORMATS,useValue:MAT_MOMENT_DATE_FORMATS}],
})

export class EscrituraDocPop implements OnInit {
  constructor(public modal:MatDialogRef<EscrituraDocPop>,public http:EscriturasService,public util:UtilService,@Inject(MAT_DIALOG_DATA) public documento:EscrituraDocumentos,public adapter:DateAdapter<any>){}
  date = new Date();
  descuentosNum=0; interesNum=0;
  ngOnInit(){
    this.adapter.setLocale('es');
  }

  CalcularInteres(){
    this.interesNum = parseFloat((this.documento.importe * (this.documento.interes / 100)).toFixed(2));
  }

  Guardar(){
    this.documento.promesa = moment(this.documento.promesa).format("YYYY-MM-DD");
    this.http.ActualizarDocumento(this.documento).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.modal.close();
  }

}