import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProveedoresService {
  url = "https://nuevoimf.angularimf.inmobiliariaimf.com/WebServices/index.php/Proveedores/";
  //url = "https://crm.inmobiliariaimf.com/WebServices/Proveedores/";
  constructor(public http:HttpClient) { }

  Proveedores(){
    return this.http.get(`${this.url}Proveedor`);
  }

  ProveedoresTipo(){
    return this.http.get(`${this.url}TipoProveedor`);
  }

  PersonaTipo(){
    return this.http.get(`${this.url}TipoPersona`);
  }

  Paises(){
    return this.http.get(`${this.url}Pais`);
  }

  Estados(idPais){
    return this.http.get(`${this.url}EstadoPais/${idPais}`);
  }

  Ciudad(idPais,idEstado){
    return this.http.get(`${this.url}CiudadEstado/${idPais}/${idEstado}`);
  }

  GuardarProveedor(proveedor){
    return this.http.post(`${this.url}CrearProveedor`,JSON.stringify(proveedor));
  }

  EditarProveedor(proveedor){
    return this.http.post(`${this.url}EditarProveedor`,JSON.stringify(proveedor));
  }

  EliminarProveedor(proveedor){
    return this.http.post(`${this.url}EliminarProveedor`,JSON.stringify(proveedor));
  }

  CuentasProveedor(idProveedor){
    return this.http.get(`${this.url}ProveedorCuenta/${idProveedor}`);
  }

  Bancos(){
    return this.http.get(`${this.url}Bancos`);
  }

  GuardarCuenta(cuenta){
    return this.http.post(`${this.url}CrearCuenta`,JSON.stringify(cuenta));
  }

  EliminarCuenta(cuenta){
    return this.http.post(`${this.url}EliminarCuenta`,JSON.stringify(cuenta));
  }

  Contactos(idProveedor){
    return this.http.get(`${this.url}ProveedorTelefono/${idProveedor}`);
  }

  GuardarContactos(contactos){
    return this.http.post(`${this.url}CrearTelefono`,JSON.stringify(contactos));
  }

  EliminarContactos(contactos){
    return this.http.post(`${this.url}EliminarTelefono`,JSON.stringify(contactos));
  }

}
