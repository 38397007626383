export class EscrituraDocumentos {
    constructor(
        public idDocumento:number,
        public nombreDocumento:string,
        public idCliente:number,
        public nombreCliente:string,
        public idParcela:number,
        public nombreParcela:string,
        public idLote:number,
        public nombreLote:string,
        public importe:number,
        public capital:number,
        public interes:number,
        public reporte:string,
        public promesa:string
    ){}
}