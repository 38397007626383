import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClienteService } from '../../../Servicios/cliente.service';
import { UtilService } from '../../../util.service';
import { ClienteCuenta } from '../../../Modelos/ClienteCuenta';

@Component({
  selector: 'app-cliente-cuentas',
  templateUrl: './cliente-cuentas.component.html',
  styleUrls: ['./cliente-cuentas.component.scss']
})
export class ClienteCuentasComponent implements OnInit {
  Columns = ['nombreBanco','cuenta','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  clienteCuenta = new ClienteCuenta(0,0,0);
  constructor(public http:ClienteService,public util:UtilService,public dialog:MatDialog) { }

  ngOnInit() {
    var datos = this.util.showDatos();
    this.clienteCuenta = {idCliente:datos.id,idBanco:0,cuenta:0};
    this.renderTable();
  }

  renderTable(){
    this.http.CuentaBancarias(this.clienteCuenta.idCliente).subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  Limpiar(){
    this.clienteCuenta = {idCliente:this.clienteCuenta.idCliente,idBanco:0,cuenta:0};
  }

  NuevoCuenta(){
    const dialogRef = this.dialog.open(cuentasPop,{
      width:"auto",
      height:"auto",
      data:this.clienteCuenta
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  Eliminar(row){
    this.clienteCuenta = {idCliente:this.clienteCuenta.idCliente,idBanco:row.banco,cuenta:row.cuenta};
    this.http.EliminarCuenta(this.clienteCuenta).subscribe(result=>{
      console.log(result);
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

}

@Component({
  selector:"cuentasPop",
  templateUrl:"cuentasPop.html"
})

export class cuentasPop implements OnInit {
  constructor(public modal:MatDialogRef<cuentasPop>,public http:ClienteService,@Inject(MAT_DIALOG_DATA) public cuenta:ClienteCuenta){}
  banco;
  ngOnInit(){
    this.Bancos();
  }
  
  Bancos(){
    this.http.Bancos().subscribe(result=>{
      this.banco = result;
    },error=>{console.log(<any>error);});
  }

  GuardarCuentas(){
    this.http.GuardarCuentas(this.cuenta).subscribe(result=>{
      console.log(result);
      this.Cancelar();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.modal.close();
  }

}