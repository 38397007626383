import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { UtilService } from '../../../util.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Compras } from '../../../Modelos/Compras';
import { ComprasService } from '../../../Servicios/compras.service';
import { Parcelas } from '../../../Interfaces/Parcela';
import { Proveedores } from '../../../Interfaces/Proveedores';
@Component({
  selector: 'app-compras',
  templateUrl: './compras.component.html',
  styleUrls: ['./compras.component.scss']
})
export class ComprasComponent implements OnInit {
  Columns = ['proveedor','parcela','estatus','costoM2','area','total','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  compra = new Compras(0,"",0,"",0,0,0);
  constructor(public http:ComprasService,public util:UtilService,public dialog:MatDialog) { }

  ngOnInit() {
    this.renderTable();
  }

  renderTable(){
    this.http.Compras().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  OpendModal():void {
    const dialogRef = this.dialog.open(comprasPop,{
      width:"auto",
      height:"auto",
      data:this.compra
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  CargarDatos(row){
    this.compra = {idParcela:row.parcela,nombreParcela:row.nombreParcela,idProveedor:row.proveedor,nombreProveedor:row.nombreProveedor,area:row.area,costoM2:row.costoM2,ubicador:1};
    this.OpendModal();
  }

  Limpiar(){
    this.compra = {idParcela:0,nombreParcela:"",idProveedor:0,nombreProveedor:"",area:0,costoM2:0,ubicador:0};
  }

  OpendModalEstatus(row):void{
    this.compra = {idParcela:row.parcela,nombreParcela:row.nombreParcela,idProveedor:row.proveedor,nombreProveedor:row.nombreProveedor,area:row.area,costoM2:row.costoM2,ubicador:1};
    const dialogRef = this.dialog.open(estatusCambio, {
      width:"auto",
      height:"auot",
      data:this.compra
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  Eliminar(row){
    this.http.EliminarCompra(row.parcela).subscribe(result=>{
      this.renderTable();
      console.log(result);
    },error=>{console.log(<any>error);});
  }

}


@Component({
  selector:"comprasPop",
  templateUrl:"comprasPop.html"
})

export class comprasPop implements OnInit {
  constructor(public modal:MatDialogRef<comprasPop>,public http:ComprasService,@Inject(MAT_DIALOG_DATA) public compra:Compras){}

  stateCtrl = new FormControl();
  FilteredParcela:Observable<Parcelas[]>;
  parcela:Parcelas[]=[];

  stateCtrlPro = new FormControl();
  FilteredPro:Observable<Proveedores[]>;
  proveedor:Proveedores[]=[];

  ngOnInit(){
    this.Proveedor();
    this.Parcelas();
  }

  Proveedor(){
    this.http.Proveedores().subscribe(result=>{
      this.proveedor = Object.values(result);
      this.FilteredPro = this.stateCtrlPro.valueChanges.pipe(startWith(''),map(pro => pro ? this._filterProveedor(pro): this.proveedor.slice()))
    },error=>{console.log(<any>error);});
  }

  _filterProveedor(value:string):Proveedores[]{
    const filtrovalue = value.toLowerCase();
    return this.proveedor.filter(pro => pro.nombre.toLowerCase().indexOf(filtrovalue) === 0);
  }

  Parcelas(){
    this.http.Parcelas().subscribe(result=>{
      this.parcela = Object.values(result);
      this.FilteredParcela = this.stateCtrl.valueChanges.pipe(startWith(''),map(par => par ? this._filterParcela(par):this.parcela.slice()))
    },error=>{console.log(<any>error);});
  }

  _filterParcela(value:string):Parcelas[]{
    const filtroParcela = value.toLowerCase();
    return this.parcela.filter(par => par.nombre.toLowerCase().indexOf(filtroParcela) === 0);
  }

  ObtenParcela(id:number){
    this.compra.idParcela = id;
  }

  ObtenProveedor(id:number){
    this.compra.idProveedor = id;
  }

  GuardarCompra(){
    this.http.GuardarCompra(this.compra).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  ActualizarCompra(){
    this.http.ActualizarCompra(this.compra).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.modal.close();
  }

}

@Component({
  selector:"estatusCambio",
  templateUrl:"estatusCambio.html"
})

export class estatusCambio implements OnInit {
  constructor(public modal:MatDialogRef<estatusCambio>,public http:ComprasService,@Inject(MAT_DIALOG_DATA) public estatus:Compras){}
  ngOnInit(){
    console.log(this.estatus);
  }
}