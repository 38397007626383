import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SeparacionService } from '../../../Servicios/separacion.service';
import { Parcelas } from '../../../Interfaces/Parcela';
import { Clientes } from '../../../Interfaces/Cliente';
import { UtilService } from '../../../util.service';
import { Separacion } from '../../../Modelos/Separacion';

@Component({
  selector: 'app-separacion',
  templateUrl: './separacion.component.html',
  styleUrls: ['./separacion.component.scss']
})
export class SeparacionComponent implements OnInit {
  Columns = ['nombreParcela','lote','nombreCliente','usuario','estatusSeparacion','enganche','pagado','restante','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  separacion = new Separacion(0,0,0,0,0,'','',0);
  constructor(public http:SeparacionService,public dialog:MatDialog,public util:UtilService) { }
  rol;
  ngOnInit() {
    this.renderTable();
    this.rol = localStorage.Rol;
    this.separacion.idAgente = localStorage.idUsuario;
  }

  renderTable(){
    this.http.Separaciones().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  OpendModal():void{
    const dialogRef = this.dialog.open(separacionPop,{
      width:"auto",
      height:"auto",
      data: this.separacion
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  CargarDatos(row){
    this.separacion = {idCliente:row.cliente,idParcela:row.parcela,idLote:row.lote,idAgente:row.agente,enganche:row.enganche,nomParcela:row.nombreParcela,nombCliente:row.nombreCliente,ubicador:1};
    this.OpendModal();
  }

  Limpiar(){
    this.separacion = {idCliente:0,idParcela:0,idLote:0,idAgente:0,enganche:0,nomParcela:"",nombCliente:"",ubicador:0};
  }

  Cancelacion(row){
    this.separacion = {idCliente:row.cliente,idParcela:row.parcela,idLote:row.lote,idAgente:row.agente,enganche:row.enganche,nomParcela:row.nombreParcela,nombCliente:row.nombreCliente,ubicador:1};
    this.http.Cancelacion(this.separacion).subscribe(result=>{
       console.log(result);
       this.renderTable();
    },error=>{console.log(<any>error);});
  }

  Eliminar(row){
    this.separacion = {idCliente:row.cliente,idParcela:row.parcela,idLote:row.lote,idAgente:row.agente,enganche:row.enganche,nomParcela:row.nombreParcela,nombCliente:row.nombreCliente,ubicador:1};
    this.http.EliminarSeparacion(this.separacion).subscribe(result=>{
      console.log(result);
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

}


@Component({
  selector:"separacionPop",
  templateUrl:"separacionPop.html"
})

export class separacionPop implements OnInit {
  constructor(public modal:MatDialogRef<separacionPop>,public http:SeparacionService,@Inject(MAT_DIALOG_DATA) public separacion:Separacion){}
  StateParcela = new FormControl();
  FilterParcela:Observable<Parcelas[]>;
  parcela:Parcelas[]=[];

  StateCliente = new FormControl();
  FilterCliente:Observable<Clientes[]>;
  cliente:Clientes[]=[];
  lotes; agentes;
  ngOnInit(){
    this.Ubicador();
  }

  Ubicador(){
    if(this.separacion.ubicador == 1){
      this.LoteParcela(this.separacion.idParcela);
      this.Agentes();
    }else{
      this.Clientes();
      this.Parcelas();
      this.Agentes();
    }
  }

  Parcelas(){
    this.http.Parcelas().subscribe(result=>{
       this.parcela = Object.values(result);
       this.FilterParcela = this.StateParcela.valueChanges.pipe(startWith(''),map(par => par ? this.filterPar(par):this.parcela.slice()))
    },error=>{console.log(<any>error);});
  }

  filterPar(value:string):Parcelas[]{
    const filtroValue = value.toLocaleLowerCase();
    return this.parcela.filter(par => par.nombre.toLocaleLowerCase().indexOf(filtroValue) === 0);
  }

  Clientes(){
    this.http.Clientes().subscribe(result=>{
      this.cliente = Object.values(result);
      this.FilterCliente = this.StateCliente.valueChanges.pipe(startWith(''),map(cli => cli ? this.filterCli(cli):this.cliente.slice()))
    },error=>{console.log(<any>error);});
  }

  filterCli(value:string):Clientes[] {
    const filtroValue = value.toLocaleLowerCase();
    return this.cliente.filter(cli => cli.nombreCliente.toLocaleLowerCase().indexOf(filtroValue) === 0);
  }

  LoteParcela(idParcela){
    this.http.Lotes(idParcela).subscribe(result=>{
      this.lotes = result;
    },error=>{console.log(<any>error);});
  }

  Agentes(){
    this.http.Agentes().subscribe(result=>{
      this.agentes = result;
    },error=>{console.log(<any>error);});
  }

  ObtenerCliente(idCliente){
    this.separacion.idCliente = idCliente;
  }

  ObtenerParcela(idParcela){
    this.LoteParcela(idParcela);
    this.separacion.idParcela = idParcela;
  }

  GuardarSeparacion(){
    this.http.GuardarSeparacion(this.separacion).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  ActualizarSeparacion(){
    this.http.ActualizarSeparacion(this.separacion).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.modal.close();
  }

}