import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfiguracionService } from '../../../Servicios/configuracion.service';
import { UtilService } from '../../../util.service';
import { EmpresaCuenta } from '../../../Modelos/EmpresaCuenta';

@Component({
  selector: 'app-empresa-cuentas',
  templateUrl: './empresa-cuentas.component.html',
  styleUrls: ['./empresa-cuentas.component.scss']
})
export class EmpresaCuentasComponent implements OnInit {
  Columns = ['nombreEmpresa','nombreBanco','cuenta','Acciones'];
  dataSource:any; 
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  constructor(public http:ConfiguracionService,public util:UtilService,public dialog:MatDialog) { }
  cuentaEmp = new EmpresaCuenta(0,0,0);

  ngOnInit() {
    var datos = this.util.showDatos();
    this.cuentaEmp.idEmpresa = datos.id;
    this.renderTable();
  }

  renderTable(){
    this.http.CuentaEmpresa(this.cuentaEmp.idEmpresa).subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  OpenModal():void {
    const dialogRef = this.dialog.open(empresaCuentaPop,{
      width:"auto",
      height:"auto",
      data: this.cuentaEmp
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  Limpiar(){
    this.cuentaEmp = {idEmpresa:this.cuentaEmp.idEmpresa,idBanco:0,cuenta:0};
  }

  CargarDatos(row){
    this.cuentaEmp = {idEmpresa:row.Empresa,idBanco:row.banco,cuenta:row.cuenta};
    this.http.CuentaEliminar(this.cuentaEmp).subscribe(result=>{
      console.log(result);
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

}


@Component({
  selector:"empresaCuentaPop",
  templateUrl:"empresaCuentaPop.html"
})

export class empresaCuentaPop implements OnInit {
  constructor(public modal:MatDialogRef<empresaCuentaPop>,public http:ConfiguracionService,@Inject(MAT_DIALOG_DATA) public cuentaEmp:EmpresaCuenta){}
  bancos;
  ngOnInit(){
    this.Bancos();
  }

  Bancos(){
    this.http.Banco().subscribe(result=>{
      this.bancos = result;
    },error=>{console.log(<any>error);});
  }

  GuardarCuenta(){
    console.log(this.cuentaEmp);
    this.http.CuentaCrear(this.cuentaEmp).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

}