export class Documentos {
    constructor(
        public idDocumento:number,
        public documento:string,
        public idCliente:number,
        public cliente:string,
        public idParcela:number,
        public parcela:string,
        public idLote:number,
        public lote:string,
        public reporte:string,
        public descuento:number,
        public promesa:string,
        public importe:number,
        public capital:number,
        public interes:number
    ){}
}