export class ClienteContactos {
    constructor(
        public idCliente:number,
        public idContacto:number,
        public idPersona:number,
        public idTipo:number,
        public idPais:number,
        public idEstado:number,
        public idCiudad:number,
        public rfc:string,
        public curp:string,
        public nombre:string,
        public apellidoP:string,
        public apellidoM:string,
        public ocupacion:string,
        public correo:string,
        public calle:string,
        public numero:number,
        public colonia:string,
        public codigoPostal:number
    ){}
}