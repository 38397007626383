import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ProveedoresService } from '../../../Servicios/proveedores.service';
import { UtilService } from '../../../util.service';
import { Proveedor } from '../../../Modelos/Proveedor';
@Component({
  selector: 'app-proveedores-show',
  templateUrl: './proveedores-show.component.html',
  styleUrls: ['./proveedores-show.component.scss']
})

export class ProveedoresShowComponent implements OnInit {
  Columns = ['nombre','rfc','direccion','ubicacion','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  proveedor = new Proveedor(0,'','',0,0,'',0,'',0,0,0,0);
  constructor(public http:ProveedoresService,public util:UtilService) { }

  ngOnInit() {
    this.renderTable();
  }

  renderTable(){
    this.http.Proveedores().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
      this.dataSource.paginator = this.paginator;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  Nuevo(){
    this.util.navegador("Proveedores/Crear");
  }

  Editar(row){
    this.util.getDatos(row);
    this.util.navegador("Proveedores/Editar");
  }

  Borrar(row){
    this.proveedor.idProveedor = row.id;
    this.http.EliminarProveedor(this.proveedor).subscribe(result=>{
      console.log(result);
    },error=>{console.log(<any>error);});
  }

  CuentasBancarias(row){
    this.util.getDatos(row.id);
    this.util.navegador("Proveedores/Cuenta");
  }

  Contactos(row){
    this.util.getDatos(row.id);
    this.util.navegador("Proveedores/Contactos");
  }

}
