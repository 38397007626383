export class ClienteDirCobro {
    constructor(
        public idCliente:number,
        public idPais:number,
        public idEstado:number,
        public idCiudad:number,
        public calle:string,
        public numero:number,
        public colonia:string,
        public codigoPostal:number,
        public long:number
    ){}
}