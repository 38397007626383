import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParcelaService } from '../../../Servicios/parcela.service';
import { UtilService } from '../../../util.service';
import { Lotes } from 'src/app/Modelos/Lotes';

@Component({
  selector: 'app-parcela-show',
  templateUrl: './parcela-show.component.html',
  styleUrls: ['./parcela-show.component.scss']
})

export class ParcelaShowComponent implements OnInit {
  Columns = ['nombre','tipoParcela','lotes','Area Vendible','Area Municipal','Area Vial','Area Total','Ubicacion','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  lote = new Lotes(0,0,"",0,0,0,0,0,0,'','','','','','','','',0);
  constructor(public http:ParcelaService,public util:UtilService,public dialog:MatDialog) { }

  ngOnInit() {
    this.renderTable();
  }

  renderTable(){
    this.http.Parcelas().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  Editar(row){
    this.util.getDatos(row);
    this.util.getSoloUno(1);
    this.util.navegador("Parcelas/Crear");
  }

  Nuevo(){
    this.util.getSoloUno(undefined);
    this.util.navegador("Parcelas/Crear");
  }

  AsignarLotes(row){
    this.OpenModal(row);
  }

  VerLotes(row){
    this.util.getDatos(row);
    this.util.navegador("Parcelas/Lotes");
  }

  OpenModal(row):void {
    const dialogRef = this.dialog.open(parcelaLotesPop,{
      width:'300',
      height:'300',
      data: this.lote = {parcela:row.id,estatus:1,tipo:1,manzana:0,area:0,ventaM2:0,costoLote:0,lote:0,loteNom:"",norte:"",noreste:"",este:"",sureste:"",sur:"",suroeste:"",oeste:"",noroeste:"",cantidad:0}
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

}


@Component({
  selector:"parcelaLotesPop",
  templateUrl:"parcelaLotesPop.html"
})

export class parcelaLotesPop implements OnInit {
  constructor(public modal:MatDialogRef<parcelaLotesPop>,public http:ParcelaService,@Inject(MAT_DIALOG_DATA) public lote:Lotes){}
  ngOnInit(){
  }

  Cancelar(){
    this.modal.close();
  }

  Guardar(){
    this.http.GuardarLote(this.lote).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

}