export class Escrituras {
    constructor(
        public idCliente:number, 
        public nombreCliente:string,
        public idParcela:number,
        public idLote:number,
        public precio:number,
        public plazo:number,
        public fijo:number,
        public inicio:string,
        public ubicador:number
    ){}
}