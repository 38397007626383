import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ComprasService {
  url = "https://nuevoimf.angularimf.inmobiliariaimf.com/WebServices/index.php/Compras/";
  //url = "https://crm.inmobiliariaimf.com/WebServices/Compras/"
  constructor(public http:HttpClient) { }

  Parcelas(){
    return this.http.get(`${this.url}MostrarParcelasD`);
  }

  Proveedores(){
    return this.http.get(`${this.url}MostrarProveedores`);
  }

  Compras(){
    return this.http.get(`${this.url}MostrarCompras`);
  }

  GuardarCompra(compra){
    return this.http.post(`${this.url}InsertarCompra`,JSON.stringify(compra));
  }

  ActualizarCompra(compra){
    return this.http.post(`${this.url}ActualizarCompra`,JSON.stringify(compra));
  }

  EliminarCompra(idParcela){
    return this.http.get(`${this.url}EliminarCompra/${idParcela}`);
  }

}
