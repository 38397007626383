import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { EscriturasService } from '../../../Servicios/escrituras.service';
import { UtilService } from '../../../util.service';
import { Escrituras } from 'src/app/Modelos/Escrituras';
import { Clientes } from '../../../Interfaces/Cliente';

@Component({
  selector: 'app-escrituras',
  templateUrl: './escrituras.component.html',
  styleUrls: ['./escrituras.component.scss']
})
export class EscriturasComponent implements OnInit {
  Columns = ['nombreParcela','numeroLote','nombreCliente','estatusEscritura','precio','plazo','fijo','inicio','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  escritura = new Escrituras(0,'',0,0,0,0,0,'',0);
  constructor(public http:EscriturasService,public util:UtilService,public dialog:MatDialog) { }

  ngOnInit() {
    this.RenderTable();
  }

  RenderTable(){
    this.http.Escrituras().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  Escrituras(){
    const dialogRef = this.dialog.open(EscrituraPop,{
      width:"auto",
      height:"auot",
      data:this.escritura
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.RenderTable();
    });
  }

  Limpiar(){
    this.escritura = {idCliente:0,nombreCliente:"",idParcela:0,idLote:0,precio:0,plazo:0,fijo:0,inicio:"",ubicador:0}
  }

  Editar(row){
    this.escritura = {idCliente:row.cliente,nombreCliente:row.nombreCliente,idParcela:row.parcela,idLote:row.lote,precio:row.precio,plazo:row.plazo,fijo:row.fijo,inicio:row.inicio,ubicador:1}
    this.Escrituras();
  }

  Aprobar(row){
    this.escritura = {idCliente:row.cliente,nombreCliente:row.nombreCliente,idParcela:row.parcela,idLote:row.lote,precio:row.precio,plazo:row.plazo,fijo:row.fijo,inicio:row.inicio,ubicador:1}
    this.http.AprobarEscritura(this.escritura).subscribe(result=>{
      console.log(result);
      this.RenderTable();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.http.CancelarEscritura(this.escritura).subscribe(result=>{
      console.log(result);
      this.RenderTable();
    },error=>{console.log(<any>error);});
  }

  Documentos(row){
    this.util.getDatos(row);
    this.util.navegador("Escritura/Documentos");
  }

}


@Component({
  selector:"EscrituraPop",
  templateUrl:"EscrituraPop.html",
  providers:[{provide:DateAdapter,useClass:MomentDateAdapter,deps:[MAT_DATE_LOCALE]},
  {provide:MAT_DATE_FORMATS,useValue:MAT_MOMENT_DATE_FORMATS}],
})

export class EscrituraPop implements OnInit {
  constructor(public modal:MatDialogRef<EscrituraPop>,public http:EscriturasService,public util:UtilService,@Inject(MAT_DIALOG_DATA) public escritura:Escrituras,public adapter:DateAdapter<any>){}
  StateCli = new FormControl();
  FilerCli:Observable<Clientes[]>;
  cliente:Clientes[]=[];
  parcela; lote;   date = new Date();

  ngOnInit(){
    this.adapter.setLocale('es');
    this.Ubicador();
  }

  Ubicador(){
    if(this.escritura.ubicador != 0){
      this.Parcela(this.escritura.idCliente);
      this.Lote(this.escritura.idParcela);
    }else {
      this.Clientes();
    }
  }

  Clientes(){
    this.http.Clientes().subscribe(result=>{
      this.cliente = Object.values(result);
      this.FilerCli = this.StateCli.valueChanges.pipe(startWith(''),map(cli => cli ? this.filter(cli):this.cliente.slice()))
    },error=>{console.log(<any>error);});
  }

  filter(value:string):Clientes[]{
    const filtroValue = value.toLowerCase();
    return this.cliente.filter(cli => cli.nombreCliente.toLowerCase().indexOf(filtroValue)===0);
  }

  Parcela(idCliente){
    this.escritura.idCliente = idCliente;
    this.http.Parcela(idCliente).subscribe(result=>{
      this.parcela = result;
    },error=>{console.log(<any>error);});
  }

  Lote(idParcela){
    this.http.Lote(this.escritura.idCliente,idParcela).subscribe(result=>{
      this.lote = result;
    },error=>{console.log(<any>error);});
  }

  Guardar(){
    this.escritura.inicio = moment(this.escritura.inicio).format("YYYY-MM-DD");
    this.http.GuardarEscritura(this.escritura).subscribe(result=>{
      this.modal.close();
      console.log(result);
    },error=>{console.log(<any>error);});
  }

  Editar(){
    this.escritura.inicio = moment(this.escritura.inicio).format("YYYY-MM-DD");
    this.http.EditarEscritura(this.escritura).subscribe(result=>{
      this.modal.close();
      console.log(result);
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.modal.close();
  }

}