import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProveedoresService } from '../../../Servicios/proveedores.service';
import { UtilService } from '../../../util.service';
import { ProveedorTel } from '../../../Modelos/ProveedorTel';
@Component({
  selector: 'app-proveedores-contactos',
  templateUrl: './proveedores-contactos.component.html',
  styleUrls: ['./proveedores-contactos.component.scss']
})

export class ProveedoresContactosComponent implements OnInit {
  Columns = ['codigo','telefono','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;

  constructor(public http:ProveedoresService,public util:UtilService,public dialog:MatDialog) { }
  proTel = new ProveedorTel(0,0,0);
  
  ngOnInit() {
    var datos = this.util.showDatos();
    this.proTel.idProveedor = datos;
    this.renderTable();
  }

  renderTable(){
    this.http.Contactos(this.proTel.idProveedor).subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  OpenModal():void {
    const dialogRef = this.dialog.open(proveedorContactoPop,{
      width:"400",
      height:"400",
      data:this.proTel
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  Limpiar(){
    this.proTel = {idProveedor:this.proTel.idProveedor,codigo:0,telefono:0};
  }

  Borrar(row){
    this.proTel = {idProveedor:this.proTel.idProveedor,codigo:row.codigo,telefono:row.telefono};
    this.http.EliminarContactos(this.proTel).subscribe(result=>{
      console.log(result);
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

}


@Component({
  selector:"proveedorContactoPop",
  templateUrl:"proveedorContactoPop.html"
})

export class proveedorContactoPop implements OnInit {
  constructor(public modal:MatDialogRef<proveedorContactoPop>,public http:ProveedoresService,@Inject(MAT_DIALOG_DATA) public proTel:ProveedorTel) {}
  
  ngOnInit(){
  }

  GuardarContacto(){
    this.http.GuardarContactos(this.proTel).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  onNoClick():void{
    this.modal.close();
  }

}