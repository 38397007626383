import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClienteService } from '../../../Servicios/cliente.service';
import { UtilService } from '../../../util.service';
import { ClienteContactos } from '../../../Modelos/ClienteContactos';
import { ContactosTel } from '../../../Modelos/ContactosTel';
@Component({
  selector: 'app-cliente-contactos',
  templateUrl: './cliente-contactos.component.html',
  styleUrls: ['./cliente-contactos.component.scss']
})
export class ClienteContactosComponent implements OnInit {
  Columns = ['nombre','direccion','ubicacion','personaContacto','tipoContacto','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  constructor(public http:ClienteService,public util:UtilService,public dialog:MatDialog) { }
  clienteContacto = new ClienteContactos(0,0,0,0,0,0,0,'','','','','','','','',0,'',0);
  contactoTelefono = new ContactosTel(0,0,0,0);

  ngOnInit() {
    var id = this.util.showDatos();
    this.clienteContacto.idCliente = id;
    this.renderTable();
  }

  renderTable(){
    this.http.ClienteContactos(this.clienteContacto.idCliente).subscribe(resulr=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = resulr;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  Nuevo():void {
    const dialogRef = this.dialog.open(ContactosPop,{
      width:"auto",
      height:"auto",
      data: this.clienteContacto
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  CargarDatos(row){
    this.clienteContacto = {idCliente:this.clienteContacto.idCliente,idContacto:row.id,idPersona:row.persona,idTipo:row.tipo,idPais:row.pais,idEstado:row.estado,idCiudad:row.ciudad,nombre:row.nombre,apellidoP:row.paterno,apellidoM:row.materno,ocupacion:row.ocupacion,correo:row.email,curp:row.curp,rfc:row.rfc,calle:row.calle,numero:row.numero,colonia:row.colonia,codigoPostal:row.cp};
    this.Nuevo();
  }

  Limpiar(){
    this.clienteContacto = {idCliente:this.clienteContacto.idCliente,idContacto:0,idPersona:0,idTipo:0,idPais:0,idEstado:0,idCiudad:0,nombre:"",apellidoP:"",apellidoM:"",ocupacion:"",correo:"",curp:"",rfc:"",calle:"",numero:0,colonia:"",codigoPostal:0};
  }

  BorrarContacto(row){
    this.clienteContacto = {idCliente:this.clienteContacto.idCliente,idContacto:row.id,idPersona:row.persona,idTipo:row.tipo,idPais:row.pais,idEstado:row.estado,idCiudad:row.ciudad,nombre:row.nombre,apellidoP:row.paterno,apellidoM:row.materno,ocupacion:row.ocupacion,correo:row.email,curp:row.curp,rfc:row.rfc,calle:row.calle,numero:row.numero,colonia:row.colonia,codigoPostal:row.cp};
    this.http.BorrarContacto(this.clienteContacto.idCliente,this.clienteContacto.idContacto).subscribe(result=>{
      console.log(result);
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

  Contactos(row){
    this.contactoTelefono = {idCliente:row.cliente,idContacto:row.id,numero:0,codigo:0};
    const dialogRef = this.dialog.open(ContactosTelPop,{
      width:"auto",
      height:"auto",
      data: this.contactoTelefono
    });
  }

}

@Component({
  selector:"ContactosPop",
  templateUrl:"ContactosPop.html"
})

export class ContactosPop implements OnInit{
  constructor(public modal:MatDialogRef<ContactosPop>,public http:ClienteService,@Inject(MAT_DIALOG_DATA) public clienteContacto:ClienteContactos){}
  pais; estado; ciudad; tContacto; tPersona;
  ngOnInit(){
    this.TipoPersona();
    this.TipoContacto();
    this.Pais();
    this.Cargar();  
  }

  Cargar(){
    if(this.clienteContacto.idContacto != 0){
      this.Estados(this.clienteContacto.idPais);
      this.Ciudad(this.clienteContacto.idPais,this.clienteContacto.idEstado);
    }
  }
  
  TipoPersona(){
    this.http.PersonaTipo().subscribe(result=>{
      this.tPersona = result;
    },error=>{console.log(<any>error);});
  }

  TipoContacto(){
    this.http.ContactoTipo().subscribe(result=>{
      this.tContacto = result;
    },error=>{console.log(<any>error);});
  }

  Pais(){
    this.http.Pais().subscribe(result=>{
      this.pais = result;
    },error=>{console.log(<any>error);});
  }

  Estados(idPais){
    this.http.Estados(idPais).subscribe(result=>{
      this.estado = result;
    },error=>{console.log(<any>error);});
  }

  Ciudad(idPais,idCiudad){
    this.http.Ciudad(idPais,idCiudad).subscribe(result=>{
      this.ciudad = result;
    },error=>{console.log(<any>error);});
  }

  Guardar(){
    this.http.GuardarContacto(this.clienteContacto).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Actualizar(){
    this.http.ActualizarContacto(this.clienteContacto).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.modal.close();
  }

}

@Component({
  selector:"ContactosTelPop",
  templateUrl:"ContactosTelPop.html",
  styleUrls: ['./cliente-contactos.component.scss']
})

export class ContactosTelPop implements OnInit{
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  validador = 0;
  Columns = ['codigo','telefono','Acciones'];

  constructor(public modal:MatDialogRef<ContactosTelPop>,public http:ClienteService,public util:UtilService,@Inject(MAT_DIALOG_DATA) public telefonoCon:ContactosTel){}

  ngOnInit(){
    this.renderTable();
  }

  renderTable(){
    this.http.TelefonosContacto(this.telefonoCon.idCliente,this.telefonoCon.idContacto).subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Mostrar(){
    this.validador = 1;
    this.Limpiar();
  }

  Guardar(){
    this.http.GuardarTelContacto(this.telefonoCon).subscribe(result=>{
      console.log(result);
      this.renderTable();
      this.Cancelar();
    },error=>{console.log(<any>error)});
  }

  Cancelar(){
    this.validador = 0;
  }

  Limpiar(){
    this.telefonoCon = {idContacto:this.telefonoCon.idContacto,idCliente:this.telefonoCon.idCliente,numero:0,codigo:0};
  }

  EliminarTel(row){
    this.telefonoCon = {idCliente:row.cliente,idContacto:row.contacto,numero:row.telefono,codigo:row.codigo};
    this.http.EliminarTelContacto(this.telefonoCon).subscribe(result=>{
      console.log(result);
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

}