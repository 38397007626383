import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ParcelaService {
  url = "https://nuevoimf.angularimf.inmobiliariaimf.com/WebServices/index.php/Parcelas/"
  //url = "https://crm.inmobiliariaimf.com/WebServices/Parcelas/";
  constructor(public http:HttpClient) { }

  Parcelas(){
    return this.http.get(`${this.url}Parcela`);
  }

  TipoParcela(){
    return this.http.get(`${this.url}TipoParcela`);
  }

  Pais(){
    return this.http.get(`${this.url}Pais`);
  }

  EstadoPais(idPais){
    return this.http.get(`${this.url}EstadoPais/${idPais}`);
  }

  CiudadEstado(idPais,idEstado){
    return this.http.get(`${this.url}CiudadEstado/${idPais}/${idEstado}`);
  }

  CrearParsela(parcela){
    return this.http.post(`${this.url}CrearParcela`,JSON.stringify(parcela));
  }

  EditarParcela(parcela){
    return this.http.post(`${this.url}EditarParcela`,JSON.stringify(parcela));
  }

  GuardarLote(lote){
    return this.http.post(`${this.url}CrearLote`,JSON.stringify(lote));
  }

  Lotes(idParcela){
    return this.http.get(`${this.url}Lotes/${idParcela}`);
  }

  EstatusLote(){
    return this.http.get(`${this.url}EstatusLote`);
  }

  CargarLote(idParcela,idLote){
    return this.http.get(`${this.url}Lote/${idParcela}/${idLote}`);
  }

  ActualizarLote(lote){
    return this.http.post(`${this.url}EditarLote`,JSON.stringify(lote));
  }

  EliminarLote(lote){
    return this.http.post(`${this.url}EliminarLote`,JSON.stringify(lote));
  }

  CambiarEstatus(lote){
    return this.http.post(`${this.url}CambiarEstatusLote`,JSON.stringify(lote));
  }

  TipoLote(){
    return this.http.get(`${this.url}TipoLote`);
  }

}
