import { OnInit, ViewChild, Inject, Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {FormControl} from '@angular/forms';
import {Observable, pipe} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PagosService } from '../../../Servicios/pagos.service';
import { UtilService } from '../../../util.service';
import { PagoVenta } from '../../../Modelos/PagoVenta';
import { Clientes } from 'src/app/Interfaces/Cliente';
import { PagoSeparacion } from '../../../Modelos/PagoSeparacion';
import { PagoEscritura } from '../../../Modelos/PagoEscritura';
import { SeparacionRecibo } from '../../../Modelos/SeparacionRecibo';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.scss']
})
export class PagosComponent implements OnInit {
  Columns = ['numeroPago','nombreParcela','numeroLote','nombreCliente','estatusPago','importe','restante','reporte','Acciones'];
  dataSourceVenta:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;

  ColumnsSeparacion = ['numeroPago','nombreParcela','numeroLote','nombreCliente','estatusPago','formaPago','importe','restante','reporte','Acciones'];
  dataSource:any;

  ColumnsEscritura = ['numeroPago','nombreParcela','numeroLote','nombreCliente','nombreDocumento','estatusPago','formaPago','importe','reporte','Acciones'];
  dataSourceEscritura:any;

  pagoVenta = new PagoVenta(0,0,0,0,'',0,0,0,0,'',0,0,'');
  pagoSeparacion = new PagoSeparacion(0,0,'',0,0,0,0,'',0,0,1,'',0);
  pagoEscritura = new PagoEscritura(0,0,0,'',0,0,0,0,0,'',0,0,'');
  recibo = new SeparacionRecibo(0,0,0,0,0);
  constructor(public http:PagosService,public util:UtilService,public dialog:MatDialog) { }
  idRol;
  ngOnInit() {
    this.pagoVenta.idUsuario = localStorage.idUsuario;
    this.pagoSeparacion.idUsuario = localStorage.idUsuario;
    this.pagoEscritura.idUsuario = localStorage.idUsuario;
    this.idRol = localStorage.Rol;
    this.TablaPagoVenta();
    this.TablaPagoSeparacion();
    this.TablaPagoEscritura();
  }

  TablaPagoVenta(){
    this.http.PagosVenta(this.pagoVenta.idUsuario,this.idRol).subscribe(result=>{
      this.dataSourceVenta = new MatTableDataSource();
      this.dataSourceVenta.data = result;
      console.log(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    },error=>{console.log(<any>error);});
  }

  TablaPagoSeparacion(){
    this.http.PagoSeparacion(this.pagoSeparacion.idUsuario,this.idRol).subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = result;
      console.log(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    },error=>{console.log(<any>error);});
  }

  TablaPagoEscritura(){
    this.http.PagosEscrituras(this.pagoEscritura.idUsuario,this.idRol).subscribe(result=>{
      this.dataSourceEscritura = new MatTableDataSource();
      this.dataSourceEscritura.data = result;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      console.log(result);
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  PagoVenta(){
    const dialogRef = this.dialog.open(pagoVentaPop,{
      width:"auto",
      height:"auto",
      data: this.pagoVenta
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.TablaPagoVenta();
    });
  }

  CargarDatosVentas(row){
    this.pagoVenta = {idPago:row.id,idCliente:row.cliente,nombreCliente:row.nombreCliente,idParcela:row.parcela,idLote:row.lote,idDocumento:row.documento,idForma:row.forma,idTipo:row.tipo,observacion:row.observacion,idUsuario:this.pagoVenta.idUsuario,importe:row.importe,restante:row.restante,reporte:row.reporte}
    this.PagoVenta();
  }

  PagoSeparacion(){
    const dialogRef = this.dialog.open(pagoSeparacion,{
      width:"auto",
      height:"auto",
      data: this.pagoSeparacion
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.TablaPagoSeparacion();
    });
  }

  CargarDatosSeparacion(row){
    this.pagoSeparacion = {idPago:row.id,idCliente:row.cliente,nombreCliente:row.nombreCliente,idParcela:row.parcela,idLote:row.lote,idUsuario:this.pagoSeparacion.idUsuario,importe:row.importe,reporte:row.reporte,restante:row.restante,idForma:row.forma,idTipo:row.tipo,observacion:row.observacion,idEstatus:0}
    this.PagoSeparacion();
  }

  PagoEscritura(){
    const dialogRef = this.dialog.open(pagoEscrituraPop,{
      width:"auto",
      height:"auto",
      data:this.pagoEscritura
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.TablaPagoEscritura();
    });
  }

  CargarDatosEscritura(row){
    this.pagoEscritura = {idPago:row.id,idDocumento:row.documento,idCliente:row.cliente,nombreCliente:row.nombreCliente,idParcela:row.parcela,idLote:row.lote,idUsuario:this.pagoEscritura.idUsuario,idForma:row.forma,idTipo:row.tipo,observacion:row.observacion,importe:row.importe,restante:row.restante,reporte:row.reporte};
    this.PagoEscritura();
  }

  LimpiarModelo(){
    this.pagoVenta = {idPago:0,idCliente:0,nombreCliente:"",idParcela:0,idLote:0,idDocumento:0,idForma:0,idTipo:0,observacion:"",idUsuario:this.pagoVenta.idUsuario,importe:0,restante:0,reporte:""};
    this.pagoSeparacion = {idPago:0,idCliente:0,nombreCliente:"",idParcela:0,idLote:0,idUsuario:this.pagoVenta.idUsuario,importe:0,reporte:"",restante:0,idForma:0,idTipo:1,observacion:"",idEstatus:0}
    this.pagoEscritura = {idPago:0,idDocumento:0,idCliente:0,nombreCliente:"",idParcela:0,idLote:0,idUsuario:this.pagoVenta.idUsuario,idForma:0,idTipo:0,observacion:"",importe:0,restante:0,reporte:""};
  }

  AprobarPagoVenta(row){
    this.pagoVenta = {idPago:row.id,idCliente:row.cliente,nombreCliente:row.nombreCliente,idParcela:row.parcela,idLote:row.lote,idDocumento:row.documento,idForma:row.forma,idTipo:row.tipo,observacion:row.observacion,idUsuario:this.pagoVenta.idUsuario,importe:row.importe,restante:row.restante,reporte:row.reporte}
    this.http.AprobarPagoV(this.pagoVenta).subscribe(result=>{
      console.log(result);
      this.TablaPagoVenta();
    },error=>{console.log(<any>error);});
  }

/*  Prueba(restante, importe){
    console.log(restante);
    console.log(importe);
  }*/

  BorrarVenta(row){
    this.pagoVenta = {idPago:row.id,idCliente:row.cliente,nombreCliente:row.nombreCliente,idParcela:row.parcela,idLote:row.lote,idDocumento:row.documento,idForma:row.forma,idTipo:row.tipo,observacion:row.observacion,idUsuario:this.pagoVenta.idUsuario,importe:row.importe,restante:row.restante,reporte:row.reporte}
    this.http.BorrarPagoV(this.pagoVenta).subscribe(result=>{
      console.log(result);
      this.TablaPagoVenta();
    },error=>{console.log(<any>error);});
  }

  BorrarSeparacion(row){
    this.pagoSeparacion = {idPago:row.id,idParcela:row.parcela,idLote:row.lote,idCliente:row.cliente,idUsuario:0,nombreCliente:"",importe:0,restante:0,reporte:"",idForma:0,idTipo:0,idEstatus:0,observacion:""};
    this.http.EliminarSeparacion(this.pagoSeparacion).subscribe(result=>{
      console.log(result);
      this.TablaPagoSeparacion();
    },error=>{console.log(<any>error);});

  }

  AprobarPagoSeparacion(row){
    this.pagoSeparacion = {idPago:row.id,idCliente:row.cliente,nombreCliente:row.nombreCliente,idParcela:row.parcela,idLote:row.lote,idUsuario:this.pagoSeparacion.idUsuario,importe:row.importe,reporte:row.reporte,restante:row.restante,idForma:row.forma,idTipo:row.tipo,observacion:row.observacion,idEstatus:0}
    this.http.AprobarSeparacion(this.pagoSeparacion).subscribe(result=>{
      console.log(result);
      this.TablaPagoSeparacion();
    },error=>{console.log(<any>error);});
  }

  AprobarEscritura(row){
    this.pagoEscritura = {idPago:row.id,idDocumento:row.documento,idCliente:row.cliente,nombreCliente:row.nombreCliente,idParcela:row.parcela,idLote:row.lote,idUsuario:this.pagoEscritura.idUsuario,idForma:row.forma,idTipo:row.tipo,observacion:row.observacion,importe:row.importe,restante:row.restante,reporte:row.reporte};
    this.http.AprobarEscritura(this.pagoEscritura).subscribe(result=>{
      console.log(result);
      this.TablaPagoEscritura();
    },error=>{console.log(<any>error);});
  }

  GenerarResivoSeparacion(row){
    this.recibo = {idPago:row.id,idCliente:row.cliente,idParcela:row.parcela,idLote:row.lote,ubicacion:row.tipo};
    this.http.Recibo(this.recibo).subscribe(result=>{
      console.log(result);
    },error=>{console.log(<any>error);});
  }

  Prueba(param:string){
    console.log(param);
  }


}

@Component({
  selector:"pagoVentaPop",
  templateUrl:"pagoVentaPop.html",
  providers:[{provide:DateAdapter,useClass:MomentDateAdapter,deps:[MAT_DATE_LOCALE]},
  {provide:MAT_DATE_FORMATS,useValue:MAT_MOMENT_DATE_FORMATS}],
})

export class pagoVentaPop implements OnInit {
  constructor(public modal:MatDialogRef<pagoVentaPop>,public http:PagosService,public util:UtilService,@Inject(MAT_DIALOG_DATA) public pago:PagoVenta, public adapter:DateAdapter<any>){}
  StateCli = new FormControl();
  FilerCli:Observable<Clientes[]>;
  cliente:Clientes[]=[];
  parcela; lote; documento; fPago;  ubicacion;
  date = new Date();

  ngOnInit(){
    this.FormaPago();
    this.TipoPago();
    this.Ubicador();
    this.adapter.setLocale('es');
  }

  Ubicador(){
    if(this.pago.idPago != 0){
      this.Parcela(this.pago.idCliente);
      this.Lote(this.pago.idParcela);
      this.Documento(this.pago.idLote);
    }else{
      this.Clientes();
    }
  }

  Clientes(){
    this.http.ClientesVenta().subscribe(result=>{
      this.cliente = Object.values(result);
      this.FilerCli = this.StateCli.valueChanges.pipe(startWith(''),map(cli => cli ? this.filter(cli):this.cliente.slice()))
    },error=>{console.log(<any>error);});
  }

  filter(value:string):Clientes[]{
    const filtroValue = value.toLowerCase();
    return this.cliente.filter(cli => cli.nombreCliente.toLowerCase().indexOf(filtroValue)===0);
  }

  Parcela(idCliente){
    this.pago.idCliente = idCliente;
    this.http.Parcelas(idCliente).subscribe(result=>{
      this.parcela = result;
    },error=>{console.log(<any>error);});
  }

  Lote(idParcela){
    this.http.Lotes(this.pago.idCliente,idParcela).subscribe(result=>{
      this.lote = result;
    },error=>{console.log(<any>error);});
  }

  Documento(idLote){
    this.http.Documentos(this.pago.idCliente,this.pago.idParcela,idLote).subscribe(result=>{
      var result2 = Object.values(result);
      result2.forEach(element => {
          element.vencimiento = moment(element.vencimiento).format("L");
      });
      this.documento = result2; 
    },error=>{console.log(<any>error);});
  }

  ObtenerRestante(param){
    this.pago.restante = param;
  }

  FormaPago(){
    this.http.FormaPago().subscribe(result=>{
      this.fPago = result;
    },error=>{console.log(<any>error);});
  }

  TipoPago(){
    this.http.TipoPago().subscribe(result=>{
      this.ubicacion = result;
    },error=>{console.log(<any>error);});
  }

  GuardarPago(){
    this.pago.reporte = moment(this.pago.reporte).format("YYYY-MM-DD");
    this.http.GuardarPago(this.pago).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  ActualizarPago(){
    this.pago.reporte = moment(this.pago.reporte).format("YYYY-MM-DD");
    this.http.ActualizatPagoVenta(this.pago).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.modal.close();
  }

}


@Component({
  selector:"pagoSeparacion",
  templateUrl:"pagoSeparacion.html",
  providers:[{provide:DateAdapter,useClass:MomentDateAdapter,deps:[MAT_DATE_LOCALE]},
  {provide:MAT_DATE_FORMATS,useValue:MAT_MOMENT_DATE_FORMATS}],
})

export class pagoSeparacion implements OnInit{
  constructor(public modal:MatDialogRef<pagoSeparacion>,public http:PagosService,public util:UtilService,@Inject(MAT_DIALOG_DATA) public pago:PagoSeparacion,public adapter:DateAdapter<any>){}
  StateCli = new FormControl();
  FilerCli:Observable<Clientes[]>;
  cliente:Clientes[]=[];
  parcela; lote; documento; fPago; ubicacion;
  date = new Date();

  ngOnInit(){
    this.adapter.setLocale('es');
    this.Ubicador();
    this.FormaPago();
    this.TipoPago();
  }

  Ubicador(){
    if(this.pago.idPago != 0){
      this.Parcela(this.pago.idCliente);
      this.Lote(this.pago.idParcela);
    }else{
      this.Clientes();
    }
  }

  FormaPago(){
    this.http.FormaPago().subscribe(result=>{
      this.fPago = result;
    },error=>{console.log(<any>error);});
  }

  TipoPago(){
    this.http.TipoPago().subscribe(result=>{
      this.ubicacion = result;
    },error=>{console.log(<any>error);});
  }

  Clientes(){
    this.http.ClienteSeparacion().subscribe(result=>{
      this.cliente = Object.values(result);
      this.FilerCli = this.StateCli.valueChanges.pipe(startWith(''),map(cli => cli ? this.filter(cli):this.cliente.slice()))
    },error=>{console.log(<any>error);});
  }

  filter(value:string):Clientes[]{
    const filtroValue = value.toLowerCase();
    return this.cliente.filter(cli => cli.nombreCliente.toLowerCase().indexOf(filtroValue)===0);
  }

  Parcela(idCliente){
    this.pago.idCliente = idCliente;
    this.http.ParcelasSeparacion(idCliente).subscribe(result=>{
      this.parcela = result;
    },error=>{console.log(<any>error);});
  }

  Lote(idParcela){
    this.http.LotesSeparacion(this.pago.idCliente,idParcela).subscribe(result=>{
      this.lote = result;
    },error=>{console.log(<any>error);});
  }

  ObtRestante(valor){
    this.pago.restante = valor;
  }

  GuardarSeparacion(){
    this.pago.reporte = moment(this.pago.reporte).format("YYYY-MM-DD");
    this.http.GuardarSeparacion(this.pago).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  ActualizaSepraracion(){
    this.pago.reporte = moment(this.pago.reporte).format("YYYY-MM-DD");
    this.http.ActualizarSeparacion(this.pago).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.modal.close();
  }

}

@Component({
  selector:"pagoEscrituraPop",
  templateUrl:"pagoEscrituraPop.html",
  providers:[{provide:DateAdapter,useClass:MomentDateAdapter,deps:[MAT_DATE_LOCALE]},
  {provide:MAT_DATE_FORMATS,useValue:MAT_MOMENT_DATE_FORMATS}],
})

export class pagoEscrituraPop implements OnInit {
  constructor(public modal:MatDialogRef<pagoEscrituraPop>,public http:PagosService,public util:UtilService,@Inject(MAT_DIALOG_DATA) public pago:PagoEscritura,public adapter:DateAdapter<any> ){}
  StateCli = new FormControl();
  FilerCli:Observable<Clientes[]>;
  cliente:Clientes[]=[];
  parcela; lote; documento; fPago; restante=0; ubicacion;
  date = new Date();

  ngOnInit(){
    this.Ubicador();
    this.adapter.setLocale('es');
    this.FormaPago();
    this.TipoPago();
  }

  Ubicador(){
    if(this.pago.idPago != 0){
      this.Parcela(this.pago.idCliente);
      this.Lote(this.pago.idParcela);
      this.Documento(this.pago.idLote);
    }else{
      this.Clientes();
    }
  }

  Clientes(){
    this.http.ClienteEscritura().subscribe(result=>{
      this.cliente = Object.values(result);
      this.FilerCli = this.StateCli.valueChanges.pipe(startWith(''),map(cli => cli ? this.filter(cli):this.cliente.slice()))
    },error=>{console.log(<any>error);});
  }

  filter(value:string):Clientes[]{
    const filtroValue = value.toLowerCase();
    return this.cliente.filter(cli => cli.nombreCliente.toLowerCase().indexOf(filtroValue)===0);
  }

  Parcela(idCliente){
    this.pago.idCliente = idCliente;
    this.http.ParcelaEscritura(idCliente).subscribe(result=>{
      this.parcela = result;
    },error=>{console.log(<any>error);});
  }

  Lote(idParcela){
    this.http.LoteEscritura(this.pago.idCliente,idParcela).subscribe(result=>{
      this.lote = result;
    },error=>{console.log(<any>error);});
  }

  Documento(idLote){
    this.http.DocumentoEscritura(this.pago.idCliente,this.pago.idParcela,idLote).subscribe(result=>{
      this.documento = result;
    },error=>{console.log(<any>error);});
  }

  Restante(res){
    this.pago.restante = res;
  }

  FormaPago(){
    this.http.FormaPago().subscribe(result=>{
      this.fPago = result;
    },error=>{console.log(<any>error);});
  }

  TipoPago(){
    this.http.TipoPago().subscribe(result=>{
      this.ubicacion = result;
    },error=>{console.log(<any>error);});
  }

  GuardarPago(){
    this.pago.reporte = moment(this.pago.reporte).format("YYYY-MM-DD");
    this.http.GuardarEscritura(this.pago).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  ActualizarPago(){
    this.pago.reporte = moment(this.pago.reporte).format("YYYY-MM-DD");
    this.http.ActualizarEsctritura(this.pago).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.modal.close();
  }

}