import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfiguracionService } from '../../../Servicios/configuracion.service';
import { UtilService } from '../../../util.service';
import { Usuarios } from '../../../Modelos/Usuarios';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  constructor(public http:ConfiguracionService,public util:UtilService,public dialog:MatDialog) { }
  Columns = ['usuario','nombre','email','tipoUsuario','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:true}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:true}) sort:MatSort;
  usuarios = new Usuarios (0,0,'','','','','','');

  ngOnInit() {
    this.renderTable();
  }

  renderTable(){
    this.http.Usuarios().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = result;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  CargarDatos(row){
    this.usuarios = {idUsuario:row.id,idTipo:row.tipo,nombre:row.nombre,apellidoP:row.paterno,apellidoM:row.materno,correo:row.email,usuario:row.usuario,password:row.pass};
    this.OpenModal();
  }

  Limpiar(){
    this.usuarios = {idUsuario:0,idTipo:0,nombre:"",apellidoP:"",apellidoM:"",correo:"",usuario:"",password:""};
  }

  Eliminar(row){
    this.usuarios = {idUsuario:row.id,idTipo:row.tipo,nombre:row.nombre,apellidoP:row.paterno,apellidoM:row.materno,correo:row.email,usuario:row.usuario,password:row.pass};
    this.http.EliminarUsuario(this.usuarios).subscribe(result=>{
      console.log(result);
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

  OpenModal():void {
    const dialogRef = this.dialog.open(usuariosPop,{
      width:"auto",
      height:"auto",
      data: this.usuarios
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

}

@Component({
  selector:"usuariosPop",
  templateUrl:"usuariosPop.html",
})

export class usuariosPop implements OnInit {
  constructor(public modal:MatDialogRef<usuariosPop>,public http:ConfiguracionService,@Inject(MAT_DIALOG_DATA) public usuario:Usuarios){}
  tipo;

  ngOnInit(){
    this.UsuarioTipo();
  }

  UsuarioTipo(){
    this.http.TipoUsuario().subscribe(result=>{
      this.tipo = result;
    },error=>{console.log(<any>error);});
  }

  GuardarUsuario(){
    this.http.GuardarUsuario(this.usuario).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  EditarUsuario(){
    this.http.EditarUsuario(this.usuario).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.modal.close();
  }

}