import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {
  url = "https://nuevoimf.angularimf.inmobiliariaimf.com/WebServices/index.php/Configuraciones/";
  //url= "https://crm.inmobiliariaimf.com/WebServices/Configuraciones/";
  constructor(public http:HttpClient) { }

  Paises(){
    return this.http.get(`${this.url}Pais`);
  }

  CrearPais(pais){
    return this.http.post(`${this.url}CrearPais`,JSON.stringify(pais))
  }

  EditarPais(pais){
    return this.http.post(`${this.url}EditarPais`,JSON.stringify(pais));
  }

  EliminarPais(pais){
    return this.http.post(`${this.url}EliminarPais`,JSON.stringify(pais));
  }

  Estados(){
    return this.http.get(`${this.url}Estado`);
  }

  EstadoCrear(estado){
    return this.http.post(`${this.url}CrearEstado`,JSON.stringify(estado));
  }

  EditarEstado(estado){
    return this.http.post(`${this.url}EditarEstado`,JSON.stringify(estado));
  }

  EliminarEstado(estado){
    return this.http.post(`${this.url}EliminarEstado`,JSON.stringify(estado));
  }

  Ciudades(){
    return this.http.get(`${this.url}Ciudad`);
  }

  EstadoPais(idPais){
    return this.http.get(`${this.url}BusquedaEstado/${idPais}`);
  }

  GuardarCiudad(ciudad){
    return this.http.post(`${this.url}CrearCiudad`,JSON.stringify(ciudad));
  }

  EditarCiudad(ciudad){
    return this.http.post(`${this.url}EditarCiudad`,JSON.stringify(ciudad));
  }

  EliminarCiudad(ciudad){
    return this.http.post(`${this.url}EliminarCiudad`,JSON.stringify(ciudad));
  }

  Banco(){
    return this.http.get(`${this.url}Bancos`);
  }

  GuardarBanco(banco){
    return this.http.post(`${this.url}CrearBanco`,JSON.stringify(banco));
  }

  EditarBanco(banco){
    return this.http.post(`${this.url}EditarBanco`,JSON.stringify(banco));
  }

  EliminarBanco(banco){
    return this.http.post(`${this.url}EliminarBanco`,JSON.stringify(banco));
  }

  Empresas(){
    return this.http.get(`${this.url}Empresas`);
  }

  CrearEmpresa(empresa){
    return this.http.post(`${this.url}CrearEmpresa`,JSON.stringify(empresa));
  }

  EditarEmpresa(empresa){
    return this.http.post(`${this.url}EditarEmpresa`,JSON.stringify(empresa));
  }

  ElminarEmpresa(empresa){
    return this.http.post(`${this.url}EliminarEmpresa`,JSON.stringify(empresa));
  }

  CiudadEstado(idPais,idEstado){
    return this.http.get(`${this.url}CiudadEstado/${idPais}/${idEstado}`);
  }

  CuentaEmpresa(idEmpresa){
    return this.http.get(`${this.url}EmpresaCuenta/${idEmpresa}`);
  }

  CuentaCrear(CuentaEmpresa){
    return this.http.post(`${this.url}CrearCuenta`,JSON.stringify(CuentaEmpresa));
  }

  CuentaEditar(CuentaEmpresa){
    return this.http.post(`${this.url}EditarCuenta`,JSON.stringify(CuentaEmpresa));
  }

  CuentaEliminar(cuentaEmpresa){
    return this.http.post(`${this.url}EliminarCuenta`,JSON.stringify(cuentaEmpresa));
  }

  Usuarios(){
    return this.http.get(`${this.url}Usuarios`);
  }

  TipoUsuario(){
    return this.http.get(`${this.url}TipoUsuario`);
  }

  GuardarUsuario(usuario){
    return this.http.post(`${this.url}CrearUsuario`,JSON.stringify(usuario));
  }

  EditarUsuario(usuario){
    return this.http.post(`${this.url}EditarUsuario`,JSON.stringify(usuario));
  }

  EliminarUsuario(usuario){
    return this.http.post(`${this.url}EliminarUsuario`,JSON.stringify(usuario));
  }

  Logear(logeo){
    return this.http.post(`${this.url}Login`,JSON.stringify(logeo));
  }

}
