import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfiguracionService } from '../../../Servicios/configuracion.service';
import { Pais } from '../../../Modelos/Pais';
import { personMoral } from '../../../util.service';
import { personFisica } from '../../../util.service';

@Component({
  selector: 'app-pais',
  templateUrl: './pais.component.html',
  styleUrls: ['./pais.component.scss']
})
export class PaisComponent implements OnInit {
  Columns = ['nombrePais','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  pais = new Pais(0,'',0);
  constructor(public http:ConfiguracionService,public dialog:MatDialog) { }

  ngOnInit() {
    this.renderTable();
  }

  renderTable(){
    this.http.Paises().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  Limpiar(){
    this.pais = {idPais:0,nombre:"",val:0};
  }

  OpenModal():void {
    const dialogRef = this.dialog.open(PaisPop,{
      width:'300',
      height:'300',
      data: this.pais
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }


  Cargar(row){
    this.pais = {idPais:row.idPais,nombre:row.nombrePais,val:1};
    this.OpenModal();
  }

  Eliminar(row){
    this.http.EliminarPais(row).subscribe(result=>{
      console.log(result);
      this.renderTable();
    },error=>{console.log(<any>error);});
  }


}


@Component({
  selector:'PaisPop',
  templateUrl:'PaisPop.html',
})

export class PaisPop implements OnInit {
  
  constructor(public modal:MatDialogRef<PaisPop>,public http:ConfiguracionService,@Inject(MAT_DIALOG_DATA) public pais:Pais){}
  ngOnInit(){
    this.blockButton();
  }

  blockButton(){
    if(this.pais.val == 0){
      document.getElementById("Editar").style.display ="none";
    }else{
      document.getElementById("Guardar").style.display = "none";
    }
  }

  onNoClick():void{
    this.modal.close();
  }

  GuardarPais(){
    this.http.CrearPais(this.pais).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  EditarPais(){
    this.http.EditarPais(this.pais).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }


}
