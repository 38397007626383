export class Venta {
    constructor(
        public idParcela:number,
        public idLote:number,
        public idCliente:number,
        public nombreCliente:string,
        public idUsuario:number,
        public plazo:number,
        public fijo:number,
        public escritura:number,
        public inicio:string,
        public ubicador:number,
    ){}
}