import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfiguracionService } from '../../../Servicios/configuracion.service';
import { Banco } from '../../../Modelos/Banco';

@Component({
  selector: 'app-banco',
  templateUrl: './banco.component.html',
  styleUrls: ['./banco.component.scss']
})
export class BancoComponent implements OnInit {
  Columns = ['nombre','rfc','razon','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  banco = new Banco(0,'','','');
  constructor(public http:ConfiguracionService,public dialog:MatDialog) { }

  ngOnInit() {
    this.renderTable();
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  renderTable(){
    this.http.Banco().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Limpiar(){
    this.banco = {idBanco:0,nombre:"",razon:"",RFC:""};
  }

  OpenModal():void {
    const dialogRef = this.dialog.open(bancoPop,{
      width:"400",
      height:"400",
      data:this.banco
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  Editar(row){
    this.banco = {idBanco:row.id,RFC:row.rfc,nombre:row.nombre,razon:row.razon};
    this.OpenModal();
  }

  Eliminar(row){
    this.banco = {idBanco:row.id,RFC:row.rfc,nombre:row.nombre,razon:row.razon};
    this.http.EliminarBanco(this.banco).subscribe(result=>{
      console.log(result);
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

}

@Component({
  selector:"bancoPop",
  templateUrl:"bancoPop.html"
})

export class bancoPop implements OnInit {
  constructor(public modal:MatDialogRef<bancoPop>,public http:ConfiguracionService,@Inject(MAT_DIALOG_DATA) public banco:Banco){}
  ngOnInit(){
    this.blockButton();
  }

  blockButton(){
    if(this.banco.idBanco == 0){
      document.getElementById("Editar").style.display ="none";
    }else{
      document.getElementById("Guardar").style.display ="none";
    }
  }

  Cerrar(){
    this.modal.close();
  }

  GuardarBanco(){
    this.http.GuardarBanco(this.banco).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  EditarBanco(){
    this.http.EditarBanco(this.banco).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

}