import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ParcelaService } from '../../../Servicios/parcela.service';
import { UtilService } from '../../../util.service';
import { Lotes } from 'src/app/Modelos/Lotes';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-lotes',
  templateUrl: './lotes.component.html',
  styleUrls: ['./lotes.component.scss']
})
export class LotesComponent implements OnInit  {
  constructor(public http:ParcelaService,public util:UtilService,public dialog:MatDialog) { }
  Columns = ['numeroLote','manzana','area','costoLote','ventaM2','tipoLote','estatusLote','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort, {static: false}) sort:MatSort;
  lote = new Lotes(0,0,'',0,0,0,0,0,0,'','','','','','','','',0);
  parcela; nombreParcela = ""; nomLote=""; validador=0; loteAct;
  canLote; tLote;
  ngOnInit() {
    var parcela = this.util.showDatos();
    this.nombreParcela = parcela.nombre;
    this.parcela = parcela.id;
    this.canLote = parseInt(parcela.lotes);
    this.renderTable();
    this.TipoLote();
  }

  renderTable(){
    this.http.Lotes(this.parcela).subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  TipoLote(){
    this.http.TipoLote().subscribe(result=>{
      this.tLote = result;
    },error=>{console.log(<any>error);});
  }

  EditarLote(row){
    this.validador = 1;
    this.http.CargarLote(row.parcela,row.id).subscribe(result=>{
       var lote = Object.values(result);
        lote.forEach(element => {
          this.nomLote = element.numeroLote;
          this.lote = {parcela:element.parcela,lote:parseInt(element.id),loteNom:element.numeroLote,estatus:element.estatus,tipo:element.tipo,manzana:element.manzana,area:element.area,ventaM2:element.ventaM2,costoLote:element.costoLote,norte:element.norte,noreste:element.noreste,este:element.este,sureste:element.sureste,sur:element.sur,oeste:element.oeste,noroeste:element.noroeste,suroeste:element.suroeste,cantidad:0};
          this.loteAct = this.lote.lote;
        });
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.validador = 0;
    this.lote = {parcela:this.lote.parcela,estatus:0,tipo:0,manzana:0,area:0,ventaM2:0,costoLote:0,lote:0,loteNom:"",norte:"",noreste:"",este:"",sureste:"",sur:"",suroeste:"",oeste:"",noroeste:"",cantidad:0}
  }

  Siguiente(){
     this.loteAct = this.lote.lote;
    var siguente = this.loteAct + 1;
    this.CargarLote(this.lote.parcela,siguente);
  }

  Anterior(){
    var loteAct = this.lote.lote;
    var anterior = loteAct - 1;
    this.CargarLote(this.lote.parcela,anterior);
  }

  CargarLote(parcela,id){
    this.http.CargarLote(parcela,id).subscribe(result=>{
      var lote = Object.values(result);
       lote.forEach(element => {
         this.nomLote = element.numeroLote;
         this.lote = {parcela:element.parcela,lote:parseInt(element.id),loteNom:element.numeroLote,estatus:element.estatus,tipo:element.tipo,manzana:element.manzana,area:element.area,ventaM2:element.ventaM2,costoLote:element.costoLote,norte:element.norte,noreste:element.noreste,este:element.este,sureste:element.sureste,sur:element.sur,oeste:element.oeste,noroeste:element.noroeste,suroeste:element.suroeste,cantidad:0};
         this.loteAct = this.lote.lote;
        });
   },error=>{console.log(<any>error);});
  }

  Editar(){
    this.http.ActualizarLote(this.lote).subscribe(result=>{
      console.log(result);
      this.renderTable();
      this.Cancelar();
    },error=>{console.log(<any>error);});
  }

  EditarSiguente(){
    this.http.ActualizarLote(this.lote).subscribe(result=>{
      console.log(result);
      this.renderTable();
      this.Siguiente();
    },error=>{console.log(<any>error);});
  }

  EliminarLote(row){
    if(this.canLote == row.id){
      this.lote.parcela = row.parcela;
      this.lote.lote = row.id;
      this.http.EliminarLote(this.lote).subscribe(result=>{
        console.log(result);
        this.renderTable();     
      },error=>{console.log(<any>error);});
    }else {
      console.log("Solo puede borrar el ultimo lote");
    }
  }

  Regresar(){
    this.util.navegador("/Parcelas");
  }

  CambiarEstatus(row){
    if(row.estatus == 1){
      this.OpenModal(row);
    }else{
      console.log("No se puede cambiar el estatus");
    }
  }


  OpenModal(row):void {
    const dialogRef = this.dialog.open(loteEstatusPop,{
      width:'300',
      height:'300',
      data: this.lote = {parcela:row.parcela,estatus:row.estatus,tipo:0,manzana:0,area:0,ventaM2:0,costoLote:0,lote:row.id,loteNom:"",norte:"",noreste:"",este:"",sureste:"",sur:"",suroeste:"",oeste:"",noroeste:"", cantidad:0}
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

}


@Component({
 selector:"loteEstatusPop",
 templateUrl:"loteEstatusPop.html"
})

export class loteEstatusPop implements OnInit {
  constructor(public model:MatDialogRef<loteEstatusPop>,public http:ParcelaService,@Inject(MAT_DIALOG_DATA) public lote:Lotes){}
  estatusLote;

  ngOnInit(){
    this.Estatus();
    console.log(this.lote);
  }

  Estatus(){
    this.http.EstatusLote().subscribe(result=>{
      this.estatusLote = result;
    },error=>{console.log(<any>error);});
  }

  Guardar(){
    this.http.CambiarEstatus(this.lote).subscribe(result=>{
      console.log(result);
      this.model.close();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.model.close();
  }

}