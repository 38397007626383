import { Component, OnInit } from '@angular/core';
import { ConfiguracionService } from '../../../Servicios/configuracion.service';
import { Login } from '../../../Modelos/Login';
import { UtilService } from '../../../util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(public http:ConfiguracionService,public util:UtilService) { }
  usuario = new Login('','');
  hide  = true;
  ngOnInit() {
    document.getElementById("sidenav").style.display="none";
    localStorage.clear();
  }

  Existencia(){
    this.http.Logear(this.usuario).subscribe(result=>{
      this.ValidacionSesion(result);
      console.log(result);
    },error=>{console.log(<any>error);});
  }

  ValidacionSesion(session){
    var datos = {id:0,idRol:0};
    session.forEach(element => {
      if(parseInt(element.numero)!= 0){
        datos = {id:element.id,idRol:element.tipo};
        localStorage.Rol = datos.idRol;
        localStorage.idUsuario = datos.id;
        this.util.navegador("/");
        document.getElementById("sidenav").style.display="inline";
      }else{
        console.log("No existe");
      }
    });
  }

}
