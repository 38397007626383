import { Component, OnInit } from '@angular/core';
import { Parcela } from '../../../Modelos/Parcelas';
import { ParcelaService } from '../../../Servicios/parcela.service';
import { UtilService } from '../../../util.service';

@Component({
  selector: 'app-parcela-crear',
  templateUrl: './parcela-crear.component.html',
  styleUrls: ['./parcela-crear.component.scss']
})
export class ParcelaCrearComponent implements OnInit {
  parcela = new Parcela(0,'',0,0,0,0,0,0,0,'','','','','','','','');
  tParcela; paises; estados; ciudad;
  constructor(public http:ParcelaService,public util:UtilService) { }

  ngOnInit() {
    this.TipoParcela();
    this.Paises();
    this.Ubicador();
  }

  Ubicador(){
    var validador = this.util.showSoloUno();
    if(validador == undefined){
        validador = 0;
        document.getElementById("Actualizar").style.display="none";
    }else{
      document.getElementById("Guardar").style.display="none";
      var datos = this.util.showDatos();
      this.CargarDatos(datos);
    }
  }

  CargarDatos(datos){
    console.log(datos);
    this.parcela = {idParcela:datos.id,nombre:datos.nombre,idTipo:datos.tipo,idPais:datos.pais,idEstado:datos.estado,idCiudad:datos.ciudad,vendible:datos.vendible,municipal:datos.municipal,vial:datos.vial,norte:datos.norte,noreste:datos.noreste,este:datos.este,sureste:datos.sureste,sur:datos.sur,suroeste:datos.suroeste,oeste:datos.oeste,noroeste:datos.noroeste};
    this.EstadoPais(this.parcela.idPais);
    this.CiudadEstado(this.parcela.idPais,this.parcela.idEstado);
  }

  TipoParcela(){
    this.http.TipoParcela().subscribe(result=>{
      this.tParcela = result;
    },error=>{console.log(<any>error);});
  }

  Paises(){
    this.http.Pais().subscribe(result=>{
      this.paises = result;
    },error=>{console.log(<any>error);});
  }

  EstadoPais(idPais){
    this.http.EstadoPais(idPais).subscribe(result=>{
      this.estados = result;
    },error=>{console.log(<any>error);});
  }

  CiudadEstado(idPais,idEstado){
    this.http.CiudadEstado(idPais,idEstado).subscribe(result=>{
      this.ciudad = result;
    },error=>{console.log(<any>error);});
  }

  GuardarParcela(){
    console.log(this.parcela);
    this.http.CrearParsela(this.parcela).subscribe(result=>{
      console.log(result);
      this.util.navegador("Parcelas")
    },error=>{console.log(<any>error);});
  }

  EditarParcela(){
    this.http.EditarParcela(this.parcela).subscribe(result=>{
      console.log(result);
      this.util.navegador("Parcelas");
    },error=>{console.log(<any>error);});
  }

}
