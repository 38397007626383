export class Proveedor {
    constructor(
        public idProveedor:number,
        public nombre:string,
        public RFC:string,
        public tipoPro:number,
        public persona:number,
        public calle:string,
        public numero:number,
        public colonia:string,
        public codigoPostal:number,
        public idCiudad:number,
        public idEstado:number,
        public idPais:number
    ){}
}