import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SeparacionService } from '../../../Servicios/separacion.service';
import { UtilService } from '../../../util.service';
import { Separacion } from '../../../Modelos/Separacion';

@Component({
  selector: 'app-canceladas',
  templateUrl: './canceladas.component.html',
  styleUrls: ['./canceladas.component.scss']
})
export class CanceladasComponent implements OnInit {

  constructor(public http:SeparacionService,public util:UtilService) { }
  Columns = ['nombreParcela','lote','nombreCliente','usuario','estatusSeparacion','enganche','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  separacion = new Separacion(0,0,0,0,0,'','',0);

  ngOnInit() {
    this.renderTanle();
  }

  renderTanle(){
    this.http.SeparacionesCanceladas().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  EliminarSeparacion(param){
    console.log(param);
  }

}
