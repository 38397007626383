export class PagoSeparacion {
    constructor(
        public idPago:number,
        public idCliente:number,
        public nombreCliente:string,
        public idParcela:number,
        public idLote:number,
        public idUsuario:number, 
        public importe:number,
        public reporte:string,
        public restante:number,
        public idForma:number,
        public idTipo:number,
        public observacion:string,
        public idEstatus:number
    ){}
}