import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EscriturasService } from '../../../Servicios/escrituras.service';
import { UtilService } from '../../../util.service';
import { EscrituraDocumentos } from '../../../Modelos/EscrituraDocumento';

@Component({
  selector: 'app-escrituras-documento-pago',
  templateUrl: './escrituras-documento-pago.component.html',
  styleUrls: ['./escrituras-documento-pago.component.scss']
})
export class EscriturasDocumentoPagoComponent implements OnInit {
  documentos = new EscrituraDocumentos(0,'',0,'',0,'',0,'',0,0,0,'','');
  constructor(public http:EscriturasService,public util:UtilService) { }
  Columns = ['numeroPago','nombreParcela','numeroLote','nombreCliente','nombreDocumento','estatusPago','formaPago','importe','reporte'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  
  ngOnInit() {
    var datos = this.util.showDatos();
    this.documentos.idDocumento = datos.id;
    this.documentos.idParcela = datos.parcela;
    this.documentos.idLote = datos.lote;
    this.documentos.idCliente = datos.cliente;
    this.RenderTable();
  }

  RenderTable(){
    this.http.ReportePago(this.documentos).subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

}
