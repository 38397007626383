import { OnInit, ViewChild, Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { VentasService } from '../../../Servicios/ventas.service';
import { UtilService } from '../../../util.service';
import { ReportPagosDocument } from '../../../Modelos/RepPagoDoc';

@Component({
  selector: 'app-reporte-pagos-documentos',
  templateUrl: './reporte-pagos-documentos.component.html',
  styleUrls: ['./reporte-pagos-documentos.component.scss']
})
export class ReportePagosDocumentosComponent implements OnInit {
  Columns = ['numeroPago','nombreParcela','numeroLote','nombreCliente','estatusPago','formaPago','importe','restante'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  constructor(public http:VentasService,public util:UtilService) { }
  report = new ReportPagosDocument(0,0,0,0);
  ngOnInit() {
    var datos = this.util.showDatos();
    this.report = {idDocumento:datos.id,idParcela:datos.parcela,idLote:datos.lote,idCliente:datos.cliente};
    this.renderTable();
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  renderTable(){
    this.http.ReportePago(this.report).subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

}
