export class Clientes {
    constructor(
        public idCliente:number,
        public idTipoCli:number,
        public nombres:string,
        public apellidoP:string,
        public apellidoM:string,
        public ocupacion:string,
        public correo:string,
        public idPersonaCli:number,
        public idEstadoCivil:number,
        public idRegimen:number,
        public idPais:number,
        public idEstado:number,
        public idCiudad:number,
        public CURP:string,
        public RFC:string,
        public calle:string,
        public numero:number,
        public colonia:string,
        public codigoPostal:number
    ){}
}