import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VentasService {
  url = "https://nuevoimf.angularimf.inmobiliariaimf.com/WebServices/index.php/Ventas/";
  //url = "https://crm.inmobiliariaimf.com/WebServices/Ventas/"
  constructor(public http:HttpClient) { }

  Ventas(){
    return this.http.get(`${this.url}MostrarVentas`);
  }

  Clientes(){
    return this.http.get(`${this.url}MostrarClientes`);
  }

  Parcelas(idCliente){
    return this.http.get(`${this.url}MostrarParcelas/${idCliente}`);
  }

  Lotes(idCliente,idParcela){
    return this.http.get(`${this.url}MostrarLotes/${idCliente}/${idParcela}`);
  }

  Agentes(){
    return this.http.get(`${this.url}MostrarAgentes`);
  }

  GuardarVenta(venta){
    return this.http.post(`${this.url}InsertarVenta`,JSON.stringify(venta));
  }

  ActualizarVenta(venta){
    return this.http.post(`${this.url}ActualizarVenta`,JSON.stringify(venta));
  }

  AprobarVenta(venta){
    return this.http.post(`${this.url}AprobarVenta`,JSON.stringify(venta));
  }

  Documentos(idCliente,idParcela,idLote){
    return this.http.get(`${this.url}MostrarDocumentos/${idCliente}/${idParcela}/${idLote}`); 
  }

  EditarDocumento(documento){
    return this.http.post(`${this.url}ActualizarDocumento`,JSON.stringify(documento));
  }

  ReportePago(docPago){
    return this.http.post(`${this.url}MostrarPagos`,JSON.stringify(docPago));
  }

  Canceladas(){
    return this.http.get(`${this.url}MostrarCanceladas`);
  }

}
