import { OnInit, ViewChild, Inject, Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VentasService } from '../../../Servicios/ventas.service';
import { UtilService } from '../../../util.service';
import { Clientes } from 'src/app/Interfaces/Cliente';
import { Venta } from '../../../Modelos/Venta';

const moment =  _moment;

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.scss']
})
export class VentasComponent implements OnInit {
  Columns = ['nombreParcela','numeroLote','nombreCliente','estatusVentas','fijo','pagado','restante','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator
  @ViewChild(MatPaginator,{static:false}) sort:MatSort;
  ventas = new Venta(0,0,0,'',0,0,0,0,'',0);
  constructor(public http:VentasService,public util:UtilService,public dialog:MatDialog) { }

  ngOnInit() {
    this.renderTable();
  }

  renderTable(){
    this.http.Ventas().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = result;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  Limpiar(){
    this.ventas = {idCliente:0,nombreCliente:"",idParcela:0,idLote:0,idUsuario:0,plazo:0,fijo:0,escritura:0,inicio:"",ubicador:0};
  }

  OpendModal():void {
    const dialogRef = this.dialog.open(ventaPop,{
      width:"auto",
      height:"auto",
      data:this.ventas
    });
    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  CargarDatos(row){
    this.ventas = {idCliente:row.cliente,nombreCliente:row.nombreCliente,idParcela:row.parcela,idLote:row.lote,idUsuario:row.agente,plazo:row.plazo,fijo:row.fijo,escritura:row.escritura,inicio:row.inicio,ubicador:1};
    this.OpendModal();
  }

  Aprobar(row){
    this.ventas = {idParcela:row.parcela,idLote:row.lote,idCliente:row.cliente,nombreCliente:"",idUsuario:0,plazo:0,fijo:0,escritura:0,inicio:"",ubicador:0};
      this.http.AprobarVenta(this.ventas).subscribe(result=>{
      console.log(result)
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

  Documentos(row){
    this.util.getDatos(row);
    this.util.navegador("Ventas/Documentos");
  }

}

@Component({
  selector:"ventaPop",
  templateUrl:"ventaPop.html",
  styleUrls: ['./ventas.component.scss'],
  providers:[{provide:DateAdapter,useClass:MomentDateAdapter,deps:[MAT_DATE_LOCALE]},
  {provide:MAT_DATE_FORMATS,useValue:MAT_MOMENT_DATE_FORMATS}],
})


export class ventaPop implements OnInit {
  constructor(public modal:MatDialogRef<ventaPop>,public http:VentasService,public util:UtilService,@Inject(MAT_DIALOG_DATA) public venta:Venta, public adapter:DateAdapter<any>){}
  StateCli = new FormControl();
  FilterCLi:Observable<Clientes[]>;
  cliente:Clientes[]=[];
  parcela; lotes; checked= false; agentes; restante;
  date = new Date();
  ngOnInit(){
    this.adapter.setLocale('es');
    this.Ubicacion();
    this.Agentes();
  }

  Ubicacion(){
    if(this.venta.ubicador == 1){
      this.Parcelas(this.venta.idCliente);
      this.Lotes(this.venta.idParcela);
      this.CargaCheckBox();
    }else {
      this.Clientes();
    }
  }

  Clientes(){
    this.http.Clientes().subscribe(result=>{
      this.cliente = Object.values(result);
      this.FilterCLi = this.StateCli.valueChanges.pipe(startWith(''),map(cli => cli ? this.filterCli(cli):this.cliente.slice()))
    },error=>{console.log(<any>error);});
  }

  filterCli(value:string):Clientes[]{
    const filtroValue = value.toLocaleLowerCase();
    return this.cliente.filter(cli => cli.nombreCliente.toLocaleLowerCase().indexOf(filtroValue) === 0);
  }

  Parcelas(idCliente){
    this.venta.idCliente = idCliente;
    this.http.Parcelas(idCliente).subscribe(result=>{
      this.parcela = result;
    },error=>{console.log(<any>error);});
  }

  Lotes(idParcela){
    this.http.Lotes(this.venta.idCliente,idParcela).subscribe(result=>{
      this.lotes = result;
      var array = Object.values(result);
      array.forEach(element => {
        this.restante = element.precioLote - element.enganche; 
      });
    },error=>{console.log(<any>error);});
  }

  Agentes(){
    this.http.Agentes().subscribe(result=>{
      this.agentes = result;
    },error=>{console.log(<any>error);});
  }

  CalcularFijo(PrecioB,Enganche){
    console.log(PrecioB,Enganche);
  }

  CheckBox(){
    if(this.checked == true ){
      this.venta.escritura = 1;
    }else {
      this.venta.escritura = 0;
    }
  }

  CargaCheckBox(){
    if(this.venta.escritura == 1){
      this.checked = true;
    }else{
      this.checked = false;
    }
  }

  GuardarVenta(){
    this.CheckBox();
    this.venta.inicio = moment(this.venta.inicio).format("YYYY-MM-DD");
    this.http.GuardarVenta(this.venta).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  ActualiarVenta(){
   this.CheckBox();
    this.venta.inicio = moment(this.venta.inicio).format("YYYY-MM-DD");
    this.http.ActualizarVenta(this.venta).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

  Cancelar(){
    this.modal.close();
  }


}