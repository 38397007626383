import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProveedoresService } from '../../../Servicios/proveedores.service';
import { UtilService } from '../../../util.service';
import { ProveedorCuenta } from '../../../Modelos/ProveedorCuenta';

@Component({
  selector: 'app-proveedores-cuentas',
  templateUrl: './proveedores-cuentas.component.html',
  styleUrls: ['./proveedores-cuentas.component.scss']
})
export class ProveedoresCuentasComponent implements OnInit {
  Columns = ['nombreProveedor','nombreBanco','cuenta','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  constructor(public http:ProveedoresService,public util:UtilService,public dialog:MatDialog) { }
  proCuenta = new ProveedorCuenta(0,0,0);

  ngOnInit() {
    var datos = this.util.showDatos();
    this.proCuenta.idProveedor = datos;
    this.renderTable();
  }

  renderTable(){
    this.http.CuentasProveedor(this.proCuenta.idProveedor).subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

  OpenModal():void {
    const dialogRef = this.dialog.open(proveedorCuentaPop,{
      width:"400",
      height:"400",
      data:this.proCuenta
    });

    dialogRef.afterClosed().subscribe(result=>{
      this.renderTable();
    });
  }

  Limpiar(){
    this.proCuenta = {idProveedor:this.proCuenta.idProveedor,idBanco:0,cuenta:0};
  }

  Borrar(row){
    this.proCuenta = {idProveedor:row.proveedor,idBanco:row.banco,cuenta:row.cuenta}
    this.http.EliminarCuenta(this.proCuenta).subscribe(result=>{
      console.log(result);
      this.renderTable();
    },error=>{console.log(<any>error);});
  }

}

@Component({
  selector:"proveedorCuentaPop",
  templateUrl:"proveedorCuentaPop.html"
})

export class proveedorCuentaPop implements OnInit {
  bancos;
  constructor(public modal:MatDialogRef<proveedorCuentaPop>,public http:ProveedoresService,@Inject(MAT_DIALOG_DATA) public proCuenta:ProveedorCuenta){}

  ngOnInit(){
    this.Bancos();
  }

  onNoClick():void{
    this.modal.close();
  }

  Bancos(){
    this.http.Bancos().subscribe(result=>{
      this.bancos = result;
    },error=>{console.log(<any>error);});
  }

  GuardarCuenta(){
    this.http.GuardarCuenta(this.proCuenta).subscribe(result=>{
      console.log(result);
      this.modal.close();
    },error=>{console.log(<any>error);});
  }

}