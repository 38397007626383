import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PagosService {
  url = "https://nuevoimf.angularimf.inmobiliariaimf.com/WebServices/index.php/Pagos/";
  //url = "https://crm.inmobiliariaimf.com/WebServices/Pagos/";
  constructor(public http:HttpClient) { }

  FormaPago(){
    return this.http.get(`${this.url}FormaPago`);
  }

  TipoPago(){
    return this.http.get(`${this.url}TipoPago`);
  }

  Recibo(recibo){
    return this.http.post(`${this.url}Recibo`,JSON.stringify(recibo));
  }

//Pagos De venta 
  PagosVenta(idUsuario,idRol){
    return this.http.get(`${this.url}PagosVenta/${idUsuario}/${idRol}`);
  }

  ClientesVenta(){
    return this.http.get(`${this.url}ClientesVenta`)
  }
  
  Parcelas(idCliente){
    return this.http.get(`${this.url}ParcelasVenta/${idCliente}`);
  }

  Lotes(idCliente,idParcela){
    return this.http.get(`${this.url}LotesVenta/${idCliente}/${idParcela}`);
  }

  Documentos(idCliente,idParcela,idLote){
    return this.http.get(`${this.url}DocumentosVenta/${idCliente}/${idParcela}/${idLote}`);
  }

  GuardarPago(pagoVenta){
    return this.http.post(`${this.url}InsertarVPago`,JSON.stringify(pagoVenta));
  }

  ActualizatPagoVenta(pagoVenta){
    return this.http.post(`${this.url}ActualizarVPago`,JSON.stringify(pagoVenta));
  }

  EliminarPago(pagoVenta){
    return this.http.post(`${this.url}EliminarVPago`,JSON.stringify(pagoVenta));
  }

  AprobarPagoV(pagoVenta){
    return this.http.post(`${this.url}AprobarVPago`,JSON.stringify(pagoVenta));
  }

  BorrarPagoV(pagoVenta){
    return this.http.post(`${this.url}EliminarVPago`,JSON.stringify(pagoVenta));
  }

  //Pago Separacion 

  PagoSeparacion(idUsuario,idRol){
    return this.http.get(`${this.url}PagosSeparacion/${idUsuario}/${idRol}`);
  }

  ClienteSeparacion(){
    return this.http.get(`${this.url}ClientesSeparacion`);
  }

  ParcelasSeparacion(idCliente){
    return this.http.get(`${this.url}ParcelasSeparacion/${idCliente}`);
  }

  LotesSeparacion(idCliente,idParcela){
    return this.http.get(`${this.url}LotesSeparacion/${idCliente}/${idParcela}`);
  }

  GuardarSeparacion(pagoSeparacion){
    return this.http.post(`${this.url}InsertarSPago`,JSON.stringify(pagoSeparacion));
  }

  ActualizarSeparacion(pagoSeparacion){
    return this.http.post(`${this.url}ActualizarSPago`,JSON.stringify(pagoSeparacion));
  }

  EliminarSeparacion(pagoSeparacion){
    return this.http.post(`${this.url}EliminarSPago`,JSON.stringify(pagoSeparacion));
  }

  AprobarSeparacion(pagoSeparacion){
    return this.http.post(`${this.url}AprobarSPago`,JSON.stringify(pagoSeparacion));
  }

  //Escritura 

  PagosEscrituras(idUsuario,idRol){
    return this.http.get(`${this.url}PagosEscritura/${idUsuario}/${idRol}`);
  }

  ClienteEscritura(){
    return this.http.get(`${this.url}ClientesEscritura`);
  }

  ParcelaEscritura(idCliente){
    return this.http.get(`${this.url}ParcelasEscritura/${idCliente}`);
  }

  LoteEscritura(idCliente,idParcela){
    return this.http.get(`${this.url}LotesEscritura/${idCliente}/${idParcela}`);
  }

  DocumentoEscritura(idCliente,idParcela,idDocumento){
    return this.http.get(`${this.url}DocumentosEscritura/${idCliente}/${idParcela}/${idDocumento}`);
  }

  GuardarEscritura(escritura){
    return this.http.post(`${this.url}InsertarEPago`,JSON.stringify(escritura));
  }

  ActualizarEsctritura(escritura){
    return this.http.post(`${this.url}ActualizarEPago`,JSON.stringify(escritura));
  }

  AprobarEscritura(escritura){
    return this.http.post(`${this.url}AprobarEPago`,JSON.stringify(escritura));
  }

}
