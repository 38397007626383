import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { routing, appRoutingProviders } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
//Component 
import { PaisComponent } from './Componentes/Configuraciones/pais/pais.component';
import { PaisPop } from './Componentes/Configuraciones/pais/pais.component';
import { EstadoComponent } from './Componentes/Configuraciones/estado/estado.component';
import { EstadoPop } from './Componentes/Configuraciones/estado/estado.component';
import { CiudadComponent } from './Componentes/Configuraciones/ciudad/ciudad.component';
import { CiudadPop } from './Componentes/Configuraciones/ciudad/ciudad.component';
import { BancoComponent } from './Componentes/Configuraciones/banco/banco.component';
import { bancoPop } from './Componentes/Configuraciones/banco/banco.component';
import { ProveedoresShowComponent } from './Componentes/Proveedor/proveedores-show/proveedores-show.component';
import { ProveedoresCrearComponent } from './Componentes/Proveedor/proveedores-crear/proveedores-crear.component';
import { ProveedoresEditarComponent } from './Componentes/Proveedor/proveedores-editar/proveedores-editar.component';
import { ProveedoresCuentasComponent } from './Componentes/Proveedor/proveedores-cuentas/proveedores-cuentas.component';
import { proveedorCuentaPop } from './Componentes/Proveedor/proveedores-cuentas/proveedores-cuentas.component'
import { ProveedoresContactosComponent } from './Componentes/Proveedor/proveedores-contactos/proveedores-contactos.component';
import { proveedorContactoPop } from './Componentes/Proveedor/proveedores-contactos/proveedores-contactos.component';
import { ParcelaCrearComponent } from './Componentes/Parcela/parcela-crear/parcela-crear.component';
import { ParcelaShowComponent } from './Componentes/Parcela/parcela-show/parcela-show.component';
import { parcelaLotesPop } from './Componentes/Parcela/parcela-show/parcela-show.component';
import { LotesComponent } from './Componentes/Parcela/lotes/lotes.component';
import { loteEstatusPop } from './Componentes/Parcela/lotes/lotes.component';
import { EmpresaComponent } from './Componentes/Configuraciones/empresa/empresa.component';
import { empresaPop } from './Componentes/Configuraciones/empresa/empresa.component';
import { EmpresaCuentasComponent } from './Componentes/Configuraciones/empresa-cuentas/empresa-cuentas.component';
import { empresaCuentaPop } from './Componentes/Configuraciones/empresa-cuentas/empresa-cuentas.component';
import { ClienteShowComponent } from './Componentes/Clientes/cliente-show/cliente-show.component';
import { clientePop } from './Componentes/Clientes/cliente-show/cliente-show.component';
import { clienteTelPop } from './Componentes/Clientes/cliente-show/cliente-show.component';
import { clienteDirPop } from './Componentes/Clientes/cliente-show/cliente-show.component';
import { UsuariosComponent } from './Componentes/Configuraciones/usuarios/usuarios.component';
import { usuariosPop } from './Componentes/Configuraciones/usuarios/usuarios.component';
import { ClienteContactosComponent } from './Componentes/Clientes/cliente-contactos/cliente-contactos.component';
import { ContactosPop } from './Componentes/Clientes/cliente-contactos/cliente-contactos.component';
import { ContactosTelPop } from './Componentes/Clientes/cliente-contactos/cliente-contactos.component';
import { ClienteCuentasComponent } from './Componentes/Clientes/cliente-cuentas/cliente-cuentas.component';
import { cuentasPop } from './Componentes/Clientes/cliente-cuentas/cliente-cuentas.component';
import { ComprasComponent } from './Componentes/Compra/compras/compras.component';
import { comprasPop } from './Componentes/Compra/compras/compras.component';
import { estatusCambio } from './Componentes/Compra/compras/compras.component';
import { SeparacionComponent } from './Componentes/Separaciones/separacion/separacion.component';
import { separacionPop } from './Componentes/Separaciones/separacion/separacion.component';
//import { PagosComponent } from './Componentes/Separaciones/pagos/pagos.component';
import { VentasComponent } from './Componentes/Venta/ventas/ventas.component';
import { ventaPop } from './Componentes/Venta/ventas/ventas.component';
import { pagoVentaPop } from './Componentes/Caja/pagos/pagos.component';
import { LoginComponent } from './Componentes/Login/login/login.component';
import { DocumentosComponent } from './Componentes/Venta/documentos/documentos.component';
import { documentoPop } from './Componentes/Venta/documentos/documentos.component';
import { ReportePagosDocumentosComponent } from './Componentes/Venta/reporte-pagos-documentos/reporte-pagos-documentos.component';
import { PagosComponent } from './Componentes/Caja/pagos/pagos.component';
import { pagoSeparacion } from './Componentes/Caja/pagos/pagos.component';
import { pagoEscrituraPop  } from './Componentes/Caja/pagos/pagos.component'
import { CanceladasComponent } from './Componentes/Separaciones/canceladas/canceladas.component';
import { EscriturasComponent } from './Componentes/Escritura/escrituras/escrituras.component';
import { EscrituraPop } from './Componentes/Escritura/escrituras/escrituras.component';
import { EscriturasDocumentosComponent } from './Componentes/Escritura/escrituras-documentos/escrituras-documentos.component'  
import { EscrituraDocPop } from './Componentes/Escritura/escrituras-documentos/escrituras-documentos.component';
import { EscriturasDocumentoPagoComponent } from './Componentes/Escritura/escrituras-documento-pago/escrituras-documento-pago.component';

//material angula
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatTreeModule} from '@angular/material/tree';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';

//Dependencia para hacer solicitudes http
import {HttpClientModule} from '@angular/common/http';
import { VentasCanceladasComponent } from './Componentes/Venta/ventas-canceladas/ventas-canceladas.component';
import { EscriturasCanceladasComponent } from './Componentes/Escritura/escrituras-canceladas/escrituras-canceladas.component';

@NgModule({
  declarations: [
    AppComponent,PaisComponent,PaisPop,EstadoComponent,EstadoPop,CiudadComponent,CiudadPop,BancoComponent,bancoPop,ProveedoresShowComponent,
    ProveedoresCrearComponent,ProveedoresEditarComponent,ProveedoresCuentasComponent,proveedorCuentaPop,ProveedoresContactosComponent,proveedorContactoPop,ParcelaCrearComponent,ParcelaShowComponent,
    parcelaLotesPop,LotesComponent,loteEstatusPop, EmpresaComponent,empresaPop,EmpresaCuentasComponent,empresaCuentaPop, ClienteShowComponent,clientePop,clienteTelPop,UsuariosComponent,usuariosPop, ClienteContactosComponent,ContactosPop,clienteDirPop,
    ContactosTelPop,ClienteCuentasComponent,cuentasPop,ComprasComponent,comprasPop,estatusCambio, SeparacionComponent,separacionPop,VentasComponent,ventaPop,pagoVentaPop, LoginComponent, DocumentosComponent,
    documentoPop,ReportePagosDocumentosComponent,PagosComponent,pagoSeparacion, CanceladasComponent, EscriturasComponent,EscrituraPop, EscriturasDocumentosComponent,EscrituraDocPop, EscriturasDocumentoPagoComponent,pagoEscrituraPop, VentasCanceladasComponent, EscriturasCanceladasComponent
  ],
  imports: [
    BrowserModule,BrowserAnimationsModule,routing,FormsModule,ReactiveFormsModule,MatInputModule,MatFormFieldModule,MatSelectModule,MatButtonModule,MatCardModule,
    MatTableModule,MatSortModule,MatPaginatorModule,MatDialogModule,MatTooltipModule,MatToolbarModule,HttpClientModule,MatIconModule,MatMenuModule,MatAutocompleteModule,
    MatDatepickerModule,MatCheckboxModule,MatSidenavModule,MatListModule,MatTreeModule,MatTabsModule,MatDividerModule,MatExpansionModule
  ],
  entryComponents:[
    PaisComponent,PaisPop,EstadoPop,CiudadPop,bancoPop,proveedorCuentaPop,proveedorContactoPop,parcelaLotesPop,loteEstatusPop,empresaPop,empresaCuentaPop,usuariosPop,clientePop,clienteDirPop,
    clienteTelPop,ContactosPop,ContactosTelPop,cuentasPop,comprasPop,estatusCambio,separacionPop,ventaPop,pagoVentaPop,documentoPop,pagoSeparacion,EscrituraPop,EscrituraDocPop,pagoEscrituraPop
  ],
  providers: [appRoutingProviders,MatDatepickerModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
