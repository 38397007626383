import { OnInit, ViewChild, Inject, Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { VentasService } from '../../../Servicios/ventas.service';
import { UtilService } from '../../../util.service';

@Component({
  selector: 'app-ventas-canceladas',
  templateUrl: './ventas-canceladas.component.html',
  styleUrls: ['./ventas-canceladas.component.scss']
})
export class VentasCanceladasComponent implements OnInit {
  Columns = ['nombreParcela','numeroLote','nombreCliente','estatusVentas','enganche','pagado','restante','Acciones'];
  dataSource:any;
  @ViewChild(MatPaginator,{static:false}) paginator:MatPaginator;
  @ViewChild(MatSort,{static:false}) sort:MatSort;
  constructor(public http:VentasService,public util:UtilService) { }

  ngOnInit() {
    this.renderTable();
  }

  renderTable(){
    this.http.Canceladas().subscribe(result=>{
      this.dataSource = new MatTableDataSource();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = result;
    },error=>{console.log(<any>error);});
  }

  Buscador(value:string){
    value = value.trim().toLowerCase();
    this.dataSource.filter = value;
    if(this.dataSource.paginator){
      this.dataSource.paginator.firstPage();
    }
  }

}
