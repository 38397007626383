export class Lotes {
    constructor(
        public parcela:number,
        public lote:number,
        public loteNom:string,
        public estatus:number,
        public tipo:number,
        public manzana:number,
        public area:number,
        public ventaM2:number,
        public costoLote:number,
        public norte:string,
        public noreste:string,
        public este:string,
        public sureste:string,
        public sur:string,
        public oeste:string,
        public noroeste:string,
        public suroeste:string,
        public cantidad:number
    ){}
}